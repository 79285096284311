const creditCard = [
  { id: 1, name: "Visa", pattern: /^4[0-9]{5}/ },
  { id: 24, name: "Naranja", pattern: /^(5[1-5]|2[2-7][0-9])[0-9]{4}/ },
  { id: 104, name: "Mastercard", pattern: /^(5[1-5]|2[2-7][0-9])[0-9]{4}/ },
  { id: 111, name: "American Express",  pattern: /^3[47][0-9]{4}/ },
  { id: 111, name: "Cabal", pattern: /^3[47][0-9]{4}/ },
];

const debitCard = [
  { id: 31, name: "Visa", pattern: /^4[0-9]{5}/ },
  { id: 105, name: "Mastercard", pattern: /^(5[1-5]|2[2-7][0-9])[0-9]{4}/ },
  { id: 106, name: "Maestro",  pattern: /^(50|5[6-9]|6[0-9])[0-9]{4}/ },
  { id: 108, name: "Cabal", pattern: /^3[47][0-9]{4}/ },
];

export const PaymentMethodId = (bin, info) => {
  if (info.credito) {
    const matchedCard = creditCard.find(({ pattern }) => pattern.test(bin));
    return matchedCard?.id;
  }

  if (info.debito) {
    const matchedCard = debitCard.find(({ pattern }) => pattern.test(bin));
    return matchedCard?.id;
  }
};
