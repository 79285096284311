import { useEffect, useState } from "react";
import Footer from "../components/body/Footer";
import Header from "../components/body/Header";
import Inicio from "../page/Inicio";
import Nota from "../components/notas/Nota";
import Video from "../components/videos/Video";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import apiAntesala from "../../config/ConfigPublic";
import { format, setDate } from "date-fns";
import { FullMonth } from "../utils/FullMonth";
import CardPlaceholder from "../components/load/CardPlaceholder";
import { Link } from "react-router-dom";
import ModalVideo from "../components/videos/ModalVideo";

const InicioController = () => {
  const days = ["DO", "LU", "MA", "MI", "JU", "VI", "SA"];
  const months = [
    "ENERO",
    "FEBRERO",
    "MARZO",
    "ABRIL",
    "MAYO",
    "JUNIO",
    "JULIO",
    "AGOSTO",
    "SEPTIEMBRE",
    "OCTUBRE",
    "NOVIEMBRE",
    "DICIEMBRE",
  ];

  const locale = {
    localize: {
      day: (n) => days[n],
      month: (n) => months[n],
    },
    formatLong: {
      date: () => "mm/dd/yyyy",
    },
  };

  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection1",
    },
  ]);

  const [eventosRecomendados, setEventosRecomendados] = useState(null);
  const [eventosDescuento, setEventosDescuento] = useState(null);
  const [eventosMes, setEventosMes] = useState(null);
  const [notas, setNotas] = useState(null);
  const [videos, setVideos] = useState(null);
  const [banners, setBanners] = useState(null);

  const fetch = async (setData, params, url) => {
    const response = await apiAntesala.get(url, { params });
    if (response.status == 201 || response.status == 200) {
      setData(response.data);
    }
  };

  const fetchEventosMes = async (setData, params, url) => {
    const response = await apiAntesala.get(url, { params });
    if (response.status == 201 || response.status == 200) {
      let markedDates = [];

      response.data.data.map((evento) => {
        evento.fechas.map((fecha) => {
          markedDates.push(new Date(fecha.fecha));
        });
      });

      setData(markedDates);
    }
  };

  useEffect(() => {
    if (!eventosMes) {
      let params = {
        ["filter[provincia]"]: "Córdoba",
        ["filter[fecha]"]: FullMonth(new Date()),
        sort: "-fechas.fecha",
        per_page: 50,
      };
      return fetchEventosMes(setEventosMes, params, "/eventos");
    }
  }, []);

  useEffect(() => {
    if (!eventosDescuento) {
      let params = {
        ["filter[provincia]"]: "Córdoba",
        ["filter[cupon]"]: "1",
        ["filter[fecha"]: format(new Date(), 'yyyy-M-dd').toString(),
        sort: "-fechas.fecha",
        per_page: 20,
      };
      return fetch(setEventosDescuento, params, "/eventos");
    }
  }, []);

  useEffect(() => {
    if (!eventosRecomendados) {
      let params = {
        ["filter[provincia]"]: "Córdoba",
        ["filter[fecha"]: format(new Date(), 'yyyy-M-dd').toString(),
        sort: "-fechas.fecha",
        per_page: 20,
      };
      return fetch(setEventosRecomendados, params, "/eventos");
    }
  }, []);

  useEffect(() => {
    if (!banners) {
      let params = {
        ["filter[posicion]"]: "CARUSEL",
        ["filter[provincia]"]: "Córdoba",
      };
      return fetch(setBanners, params, "/banners");
    }
  }, []);

  useEffect(() => {
    if (!notas) {
      return fetch(setNotas, {}, "/notas");
    }
  }, []);

  useEffect(() => {
    if (!videos) {
      return fetch(setVideos, {}, "/videos");
    }
  }, []);
  return (
    <div className="container-fluid px-0 bg-light">
      <div className="w-100 shadow-sm mb-1 fixed-top bg-white">
        <Header discount={false} />
      </div>

      <div className="container-md min-vh-100">
        <Inicio
          CalendarProps={{ days, months, locale, state, setState }}
          eventosRecomendados={eventosRecomendados ?? { data: null }}
          eventosDescuento={eventosDescuento ?? { data: null }}
          eventosMes={eventosMes ?? { data: [] }}
          banners={banners?.data ?? null}
        />
      </div>

      <section class="bg-dark w-full">
        <div class="container-md border-0 py-5">
          <div className="">
            <h2
              className="text-dark border-0 text-center mx-auto text-white fs-5"
              style={{ width: "300px", borderRadius: "0px 20px 20px 20px" }}
            >
              Notas
            </h2>
          </div>

          <div className="w-100 d-flex border-0">
            <div
              className="d-none d-md-flex justify-content-center border-0"
              style={{ width: "7%" }}
            >
              <h2
                className="text-dark text-center mx-auto d-none d-md-flex align-items-center justify-content-center text-white fs-5 text-rotate-90 border-0 bg-naranja text-center my-auto "
                style={{
                  height: "70px",
                  paddingLeft: "200px",
                  paddingRight: "200px",
                  borderRadius: "20px 20px 20px 20px",
                }}
              >
                Notas
              </h2>
            </div>
            <div className="w-carousel-home border-0 position-relative w-custom-nota">
              <Carousel
                responsive={{
                  superLargeDesktop: {
                    breakpoint: { max: 4000, min: 3000 },
                    items: 3,
                  },
                  desktop: {
                    breakpoint: { max: 3000, min: 1024 },
                    items: 3,
                  },
                  tablet: {
                    breakpoint: { max: 1024, min: 464 },
                    items: 1,
                  },
                  mobile: {
                    breakpoint: { max: 464, min: 0 },
                    items: 1,
                  },
                }}
                infinite={true}
                autoPlay={true}
              >
                {notas?.data
                  ? notas.data.map((nota, index) => (
                      <div class="item p-1">
                        <Nota data={nota} key={index} />
                      </div>
                    ))
                  : [1, 2, 3, 4].map((evento, index) => (
                      <div
                        class="item p-1"
                        style={{
                          background: "none",
                          width: "95%",
                          borderRadius: "20px",
                        }}
                        key={index}
                      >
                        <CardPlaceholder />
                      </div>
                    ))}
              </Carousel>
            </div>
          </div>

          <div className="w-100 d-flex justify-content-center my-5">
            <Link
              to="/contenidos/notas"
              className="btn btn-outline-light"
              style={{ borderRadius: "20px" }}
            >
              Ver todas las notas
            </Link>
          </div>
        </div>
      </section>

      <section class="bg-dark w-full">
        <div className="container-md border-0 py-5">
          <div className="">
            <h2
              className="text-dark border-0 text-center mx-auto fs-5 text-white"
              style={{ width: "300px", borderRadius: "0px 20px 20px 20px" }}
            >
              Videos
            </h2>
          </div>

          <div class="w-100 d-flex border-0">
            <div className="w-carousel-home border-0 position-relative w-custom-nota">
              <Carousel
                responsive={{
                  superLargeDesktop: {
                    breakpoint: { max: 4000, min: 3000 },
                    items: 3,
                  },
                  desktop: {
                    breakpoint: { max: 3000, min: 1024 },
                    items: 3,
                  },
                  tablet: {
                    breakpoint: { max: 1024, min: 464 },
                    items: 1,
                  },
                  mobile: {
                    breakpoint: { max: 464, min: 0 },
                    items: 1,
                  },
                }}
              >
                {videos?.data
                  ? videos.data.map((video, index) => (
                      <div class="item p-1">
                        <Video data={video} key={index} />
                      </div>
                    ))
                  : [1, 2, 3, 4].map((evento, index) => (
                      <div
                        class="item p-3"
                        style={{
                          background: "none",
                          width: "95%",
                        }}
                        key={index}
                      >
                        <CardPlaceholder />
                      </div>
                    ))}
              </Carousel>
            </div>

            <ModalVideo />
            <div
              className="d-none d-md-flex justify-content-center border-0"
              style={{ width: "7%" }}
            >
              <h2
                className="text-dark text-center mx-auto d-none d-md-flex align-items-center justify-content-center text-white fs-5 text-rotate-90 border-0 bg-amarillo text-center my-auto"
                style={{
                  height: "70px",
                  paddingLeft: "200px",
                  paddingRight: "200px",
                  borderRadius: "20px 20px 20px 20px",
                }}
              >
                Videos
              </h2>
            </div>
          </div>

          <div className="w-100 d-flex justify-content-center my-5">
            <Link
              to="/contenidos/videos"
              className="btn btn-outline-light"
              style={{ borderRadius: "20px" }}
            >
              Ver todos los videos
            </Link>
          </div>
        </div>
      </section>
      <div className="w-100 container-fluid px-0 mx-0">
        <Footer />
      </div>
    </div>
  );
};

export default InicioController;
