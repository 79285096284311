const EventoPlaceholder = ({ width, height }) => {
  return (
    <a href="#" title="Ir al evento">
      <div
        className="w-100 box-evento-hover border border-3 overflow-hidden"
        style={{ borderRadius: "20px" }}
      >
        <div className="box-evento position-relative">
          <div
            className="w-100 box-evento-hover border overflow-hidden border placeholder-wave"
            style={{
              borderRadius: "0px",
              width: "",
              height: "300px",
            }}
            aria-hidden="true"
          >
            <span
              class="placeholder w-100 d-block"
              style={{ height: "300px", background: "#c6c6c6" }}
            ></span>
          </div>

          <div
            className="w-100 text-white text-center Grifter bg-naranja"
            style={{ borderRadius: "0px 0px 0px 0px", fontSize: "10px" }}
          ></div>

          <div className="text-center px-3 py-2">
            <h1 className="Grifter fs-6  text-dark fs-6">
              {" "}
              <div
                className="w-100 box-evento-hover border overflow-hidden border placeholder-wave"
                style={{
                  borderRadius: "0px",
                  width: "",
                  height: "15px",
                }}
                aria-hidden="true"
              >
                <span
                  class="placeholder w-100 d-block"
                  style={{ height: height ?? "300px", background: "#c6c6c6" }}
                ></span>
              </div>
            </h1>
            <div className="precio fw-bold fs-4  mt-2 mb-2 text-dark">
              <div
                className="w-100 box-evento-hover border overflow-hidden border placeholder-wave"
                style={{
                  borderRadius: "0px",
                  width: "",
                  height: "15px",
                }}
                aria-hidden="true"
              >
                <span
                  class="placeholder w-100 d-block "
                  style={{ height: height ?? "300px", background: "#c6c6c6" }}
                ></span>
              </div>
            </div>
            <span
              className="text-dark w-100 text-center"
              style={{ fontSize: "12px" }}
            >
              <div
                className="w-100 box-evento-hover border overflow-hidden border placeholder-wave"
                style={{
                  borderRadius: "0px",
                  width: "",
                  height: "15px",
                }}
                aria-hidden="true"
              >
                <span
                  class="placeholder w-100 d-block"
                  style={{ height: height ?? "300px", background: "#c6c6c6" }}
                ></span>
              </div>
            </span>
            <button className="btn btn-custom-amarillo Grifter py-1 my-2"></button>
          </div>
        </div>
      </div>
    </a>
  );
};

export default EventoPlaceholder;
