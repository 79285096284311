import { Link } from "react-router-dom";
import ComprarEntradas from "../components/evento/ComprarEntradas";
import { useState } from "react";
import { PATH_EVENTO_PRODUCTION } from "../../const/PATH";

const Entrada = ({
  setStep,
  step,
  entradas,
  onClick,
  comission,
  form,
  evento,
  label,
  me
}) => {
  const [image, setImage] = useState(
    `${PATH_EVENTO_PRODUCTION}${evento?.imagen}`
  );
  const manejarError = () => {
    setImage("/assets/img/eventos/imageNotFound.svg");
  };
  return (
    <main className="m-0" style={{ paddingTop: "100px" }}>
      <section className="bg-gris-claro pt-4 pb-5 w-100">
        <div className="container-md">
          <div className="row">
            <div className="col-md-12 mb-3">
              <Link
                href=""
                className="btn btn-outline-dark py-3"
                style={{ borderRadius: "20px" }}
              >
                <i className="bi bi-chevron-left"></i>
                Volver
              </Link>
            </div>
            <div className="col-md-6 wow bounceInLeft d-none d-md-block">
              <div
                className="border h-100"
                style={{
                  backgroundImage: `url(${image})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  borderRadius: "20px",
                }}
              ></div>
            </div>
            <div className="col-md-6">
              {evento?.categorias.map((categoria) => (
                <p
                  className="Grifter bg-naranja text-white px-2 py-2 d-inline-block me-1"
                  style={{ borderRadius: "10px", fontSize: "12px" }}
                >
                  {categoria.nombre}
                </p>
              ))}
              <h3 className="Grifter GothamBold fs-4 text-uppercase wow bounceInUp">
                {evento.nombre}
              </h3>
              <h4 className="Grifter text-uppercase fs-6 mb-2 wow bounceInUp">
                {evento.nombre_grupo}
              </h4>
              <div className="border d-block d-md-none">
                <img
                  src={image}
                  className="img-fluid"
                  style={{ borderRadius: "20px" }}
                  alt=""
                  onError={manejarError}
                />
              </div>

              <span className="fw-bold" style={{ fontSize: "10px" }}>
                Proxima función
              </span>
              <p className="text-uppercase wow bounceInUp">
                Viernes, 25 de mayo 22hrs
              </p>

              <ul className="list-group mt-3 wow bounceInUp">
                <span
                  className="Grifter fw-bold mb-2"
                  style={{ fontSize: "10px" }}
                >
                  LUGAR
                </span>
                <li className="GothamLight text-uppercase d-flex align-items-center mb-2 fw-bold">
                  <i class="bi bi-buildings fw-bold me-2"></i>
                  {evento.nombre_sitio}
                </li>
                <span
                  className="Grifter fw-bold mb-2"
                  style={{ fontSize: "10px" }}
                >
                  UBICACIÓN
                </span>
                <li className="GothamLight text-uppercase d-flex mb-2 fw-bold">
                  <i className="bi bi-geo-alt-fill me-2 fw-bold"></i>
                  {`${evento.direccion}, ${evento.localidad} ${evento.provincia}`}
                </li>
              </ul>
              <span className="fw-bold" style={{ fontSize: "10px" }}>
                PRECIO DESDE
              </span>
              <div className="fs-3 fw-bold text-custom-naranja">
                {evento?.entradas[0].precio <= 0
                  ? "GRATIS"
                  : evento?.entradas[0].precio}
                <span className="fs-6 fw-normal">{`(+${evento.comisiones.tasa}% por compra online)`}</span>
              </div>
              <div>
                <ul className="list-group list-group-horizontal">
                  <li className="list-group-item border-0 fw-bold bg-transparent">
                    <i className="bi bi-clock me-2"></i>
                    {`${evento.duracion} min`}
                  </li>
                  <li className="list-group-item border-0 fw-bold bg-transparent">
                    <i className="bi bi-person-standing me-2"></i>
                    {evento.grupo}
                  </li>
                </ul>
              </div>
              <button
                className="fs-3 btn btn-custom-amarillo mt-3 wow bounceInUp py-3 w-100"
                type="button"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
              >
                comprar entradas
              </button>

              {
                <ComprarEntradas
                  setStep={setStep}
                  step={step}
                  entradas={entradas}
                  onClick={onClick}
                  comission={comission}
                  form={form}
                  evento={evento}
                  fechas={evento?.fechasJSON ?? []}
                  label={label}
                  image={image}
                  me={me}
                />
              }
            </div>
          </div>
        </div>
      </section>

      <section id="ticket-detail" className="pt-100 pb-100">
        <div className="container">
          <div className="row">
            <div
              className="col-12 mb-5 wow bounceInUp"
              dangerouslySetInnerHTML={{ __html: evento.descripcion }}
            ></div>
            <div className="col-12 col-md-6 position-relative wow bounceInUp">
              <h3>FICHA TÉCNICA</h3>
              {Object.keys(evento.ficha_tecnica).map((key, index) => (
                <p className="mb-2" key={index}>
                  <strong>{key}</strong> {evento.ficha_tecnica[key]}
                </p>
              ))}
            </div>
            <div className="col-12 col-md-6 position-relative wow bounceInUp">
              <h3>LINKS</h3>
              {evento.link
                ? Object.keys(evento.link).map((key, index) => (
                    <div className="mb-3">
                      <a href={evento.link[key]} className="mb-2">
                        {key}
                      </a>
                    </div>
                  ))
                : null}
            </div>
            {evento.url_youtube ? (
              <div className="col-12 wow bounceInUp mt-5 mb-5">
                <h3>trailers</h3>
                <iframe
                  width="100%"
                  height="500px"
                  src={evento.url_youtube}
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </section>

      <section
        id="ticket-detail"
        className="py-3 border border-bottom-0 border-start-0 border-end-0 border-dark"
      >
        <div className="container">
          <div className="text-center my-5 p-1">
            <div className="d-flex justify-content-between">
              <span></span>
              <div className="text-end">
                <span className="d-block fw-bold">{evento?.email_sitio}</span>
                <span className="d-block ">{`${evento.direccion}, ${evento.localidad} ${evento.provincia}`}</span>
                <span className="d-block ">{evento?.email_sitio}</span>
                <span className="d-block ">{evento?.telefono_sitio}</span>
              </div>
            </div>
            <iframe
              src={
                "https://maps.google.com/?q=" +
                encodeURI(
                  `${evento.direccion}, ${evento.localidad} ${evento.provincia}`
                ) +
                "&z=14&t=m&output=embed"
              }
              style={{ border: "0" }}
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
              height="400px"
              className="my-3 w-100"
            ></iframe>
          </div>
        </div>
      </section>
    </main>
  );
};

export default Entrada;
