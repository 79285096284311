const NotaPlaceholder = ({ width, height }) => {
  return (
    <div
      class="row bg-light p-0 m-0 overflow-hidden"
      style={{ borderRadius: "20px" }}
    >
      <div className="col-md-12 overflow-hidden p-0 m-0">
        <div
          className="w-100 box-evento-hover border overflow-hidden border placeholder-wave"
          style={{
            borderRadius: "20px",
            width: width ?? "",
            height: height ?? "300px",
          }}
          aria-hidden="true"
        >
          <span
            class="placeholder w-100 d-block"
            style={{ height: height ?? "300px", background: "#c6c6c6" }}
          ></span>
        </div>
      </div>

      <div
        className="text-dark px-3 py-4 border-0 col-md-12"
        style={{
          bottom: "0px",
          borderRadius: "0px 0px 0px 0px",
        }}
      >
        <div className="mb-2">
          <span className="fs-7 mb-2 text-dark d-block GothamLight">
            <div
              className="w-100 box-evento-hover border overflow-hidden border placeholder-wave"
              style={{ borderRadius: "0px", width: "100%" }}
              aria-hidden="true"
            >
              <span
                class="placeholder w-100 d-block"
                style={{ height: "15px", background: "#c6c6c6" }}
              ></span>
            </div>
          </span>
          <span className="fs-5 GothamBold text-uppercase "></span>
        </div>

        <p class="card-text placeholder-wave">
          <span class="placeholder col-7" 
            style={{ background: "#c6c6c6" }}></span>
          <span class="placeholder col-4" 
            style={{ background: "#c6c6c6" }}></span>
          <span class="placeholder col-4" 
            style={{ background: "#c6c6c6" }}></span>
          <span class="placeholder col-6" 
            style={{ background: "#c6c6c6" }}></span>
          <span class="placeholder col-8" 
            style={{ background: "#c6c6c6" }}></span>
        </p>
        <a
          href="#"
          className="d-block  text-dark border-0"
          style={{ borderRadius: "20px" }}
        >
          <span className="d-flex fs-8 GothamLight justify-content-start items-align-center">
            <div
              className="w-100 box-evento-hover border overflow-hidden border placeholder-wave"
              style={{ borderRadius: "0px", width: "100%" }}
              aria-hidden="true"
            >
              <span
                class="placeholder w-100 d-block"
                style={{ height: "15px", background: "#c6c6c6" }}
              ></span>
            </div>
          </span>
        </a>
      </div>
    </div>
  );
};

export default NotaPlaceholder;
