import { useEffect, useState } from "react";
import apiAntesala from "../../config/ConfigPublic";
import Footer from "../components/body/Footer";
import Header from "../components/body/Header";
import Videos from "../page/Videos";
import ModalVideo from "../components/videos/ModalVideo";

const VideosController = () => {
  const [videos, setVideos] = useState(null);
  const [filter, setFilter] = useState({
    per_page: 30,
  });

  const fetch = async (setData, params, url) => {
    const response = await apiAntesala.get(url, { params });
    if (response.status == 201 || response.status == 200) {
      setData(response.data);
    }
  };

  useEffect(() => {
    if (!videos) {
      let params = {
        ...filter,
      };
      return fetch(setVideos, params, "/videos");
    }
  }, []);

  const onClick = (e, value) => {
    switch (e.target.name) {
      case "reproducir":
        {
            document.getElementById('iframe-video').src = value.url;
        }
    break;
    }
  };

  return (
    <div className="container-fluid px-0 bg-amarillo">
      <div className="w-100 shadow-sm mb-1 fixed-top bg-white">
        <Header discount={false} />
      </div>

      <div className="container-md min-vh-100 ">
        <Videos
          video={videos?.data[0] ?? null}
          videos={videos ?? { data: null }}
          onClick={onClick}
        />

        <ModalVideo/>

      </div>
      <div className="w-100 container-fluid px-0 mx-0">
        <Footer />
      </div>
    </div>
  );
};

export default VideosController;
