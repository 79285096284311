const SalaPlaceholder = ({ width, height}) => {
  return (
    <div
      class="row bg-light p-0 m-0 overflow-hidden mb-2"
      style={{ borderRadius: "20px" }}
    >
      <div className="col-md-12 overflow-hidden p-0 m-0">
        <div
          className="w-100 box-evento-hover border overflow-hidden border placeholder-wave"
          style={{
            borderRadius: "20px",
            width: width ?? "",
            height: height ?? "200px",
          }}
          aria-hidden="true"
        >
          <span
            class="placeholder w-100 d-block"
            style={{ height: height ?? "300px", background: "#c6c6c6" }}
          ></span>
        </div>
      </div>
    </div>
  );
};

export default SalaPlaceholder;
