const MembresiaBar = ({ data }) => {
  return (
    <div
      className="offcanvas offcanvas-start"
      tabindex="-1"
      id="offcanvasTop-membresia"
      aria-labelledby="offcanvasTopLabel"
    >
      <div className="offcanvas-header py-1" style={{ height: "20px" }}>
        <h5
          className="offcanvas-title Grifter"
          id="offcanvasTopLabel-membresia"
          style={{ fontSize: "12px" }}
        >
          Membresias
        </h5>
        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        ></button>
      </div>
      <div className="offcanvas-body">
        <div className="container-md">
          <ul className="list-group list-group-horizontal">
            {data?.membresias.length > 0
              ? data?.membresias.map((membresia) => {
                  return (
                    <li
                      className="list-group-item border text-center mx-2"
                      style={{ minWidth: "200px", borderRadius: "20px" }}
                    >
                      <div
                        className="mx-auto border  d-flex justify-content-center align-items-center"
                        style={{
                          width: "100px",
                          height: "100px",
                          borderRadius: "50%",
                        }}
                      >
                        <span className="Grifter" style={{fontSize: "12px"}}>{membresia.name}</span>
                      </div>
                      <span
                        className="d-block Grifter"
                        style={{ fontSize: "10px" }}
                      >
                        {membresia.description}
                      </span>
                      <span
                        className="d-block Grifter"
                        style={{ fontSize: "12px" }}
                      >
                        Entradas disponibles
                      </span>
                      <span className="d-block Grifter fs-2">1</span>
                    </li>
                  );
                })
              : ""}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default MembresiaBar;
