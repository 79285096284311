import CardPlaceholder from "../components/load/CardPlaceholder";
import SalaPlaceholder from "../components/load/SalaPlaceholder";
import Filtro from "../components/salas/Filtro";
import Sala from "../components/salas/Sala";

const Salas = ({ data, setFilter, filter, fetch, setSalas, provincias }) => {
  return (
    <div
      className="w-100"
      style={{ paddingTop: "120px", paddingBottom: "50px" }}
    >
      <div className="row">
        <div className="col-21 col-md-12">
          <Filtro
            setFilter={setFilter}
            filter={filter}
            fetch={fetch}
            setSalas={setSalas}
            provincias={provincias}
          />
        </div>
        {data?.data
          ? data?.data.map((sala, index) => {
              return (
                <div className="col-6 col-md-3" key={index}>
                  <Sala data={sala} />
                </div>
              );
            })
          : [1, 2, 3, 4, 5, 6, 8, 9, 10, 11, 12, 13, 14, 15].map((d, index) => {
              return (
                <div className="col-6 col-md-3" key={index}>
                  <SalaPlaceholder />
                </div>
              );
            })}
      </div>
    </div>
  );
};

export default Salas;
