import { Link, useNavigate } from "react-router-dom";
import Cookie from "../cookies/Cookie";
import { useEffect, useState } from "react";
import clienteAntesala from "../../../config/Config";
import { useDispatch, useSelector } from "react-redux";
import { fetchMe, meState } from "../../../web/models/users/Index";
import MembresiaBar from "../perfil/MembresiaBar";
import { Logout } from "../../../utils/Logout";
import EntradasBar from "../perfil/EntradasBar";

const Header = ({ discount }) => {
  const dispatch = useDispatch();
  const _me = useSelector(meState);
  const me = _me.me;
  const navigate = useNavigate();
  const bar = [
    {
      name: "INICIO",
      link: "/inicio",
      dropdown: null,
      show: true,
    },
    {
      name: "ENTRADAS",
      link: "/entradas",
      dropdown: null,
      show: true,
    },
    {
      name: "CONTENIDOS",
      link: "#",
      show: true,
      dropdown: [
        {
          name: "NOTAS",
          link: "/contenidos/notas",
        },
        {
          name: "VIDEOS",
          link: "/contenidos/videos",
        },
      ],
    },
    {
      name: "SALAS",
      link: "/salas",
      dropdown: null,
      show: true,
    },
    {
      name: "FESTIVALES",
      link: "/festivales",
      dropdown: null,
      show: false,
    },
    {
      name: "DESCUENTOS",
      link: "/descuentos",
      dropdown: null,
      show: discount,
    },
  ];

  const provincias = ["Córdoba", "Tucumán", "Santa Fe", "Entre Ríos"];
  const geolocation = localStorage.getItem("geolocation");

  const [search, setSearch] = useState("");

  const onClick = (e) => {
    switch (e.target.name) {
      case "search":
        {
          return navigate(`/entradas/${search}`);
        }
        break;
      case "login":
        {
          return navigate(`/login`);
        }
        break;

      default:
        break;
    }
  };

  useEffect(() => {
    if (!me) {
      let params = {
        url: "/auth/me",
      };
      dispatch(fetchMe(params));
    }
  }, []);

  return (
    <div className="container-md ">
      <nav className="navbar navbar-expand-lg bg-body-tertiary">
        <div className="container-fluid">
          <a className="navbar-brand" href="#">
            <img
              src="/assets/img/marca/antesala-logo.svg"
              width="100px"
              alt=""
            />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              {bar.map((menu) => {
                if (menu.dropdown) {
                  return (
                    <li
                      className={`nav-item dropdown ${
                        menu.show ? "" : "d-none"
                      }`}
                    >
                      <Link
                        className="nav-link dropdown-toggle fs-7 text-dark fw-bold"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        {menu.name}
                      </Link>
                      <ul className="dropdown-menu">
                        {menu.dropdown.map((submenu) => {
                          return (
                            <li>
                              <Link
                                className="dropdown-item fs-7 text-dark fw-bold"
                                to={submenu.link}
                              >
                                {submenu.name}
                              </Link>
                            </li>
                          );
                        })}
                      </ul>
                    </li>
                  );
                } else {
                  return (
                    <li className={`nav-item ${menu.show ? "" : "d-none"}`}>
                      <Link
                        className="nav-link active fs-7 text-dark fw-bold"
                        aria-current="page"
                        to={menu.link}
                      >
                        {menu.name}
                      </Link>
                    </li>
                  );
                }
              })}
            </ul>

            <div className="d-md-flex d-block">
              <div className="d-flex mb-2">
                <input
                  className="form-control border border-dark"
                  style={{ borderRadius: "20px 0px 0px 20px" }}
                  type="search"
                  placeholder="Buscar"
                  onChange={(e) => setSearch(e.target.value)}
                />
                <label
                  className="btn btn-dark border-0  me-2"
                  style={{ borderRadius: "0px 20px 20px 0px" }}
                >
                  <i class="bi bi-search"></i>
                  <button
                    className="d-none"
                    name="search"
                    onClick={(e) => onClick(e)}
                  ></button>
                </label>
              </div>

              <div class="dropdown mb-2">
                <button
                  class="btn btn-outline-dark dropdown-toggle w-100"
                  style={{ borderRadius: "20px" }}
                  type="button"
                  id="dropdownMenuButton1"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {geolocation}
                </button>
                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                  {provincias.map((provincia) => (
                    <li>
                      <label className="dropdown-item">
                        {provincia}
                        <button
                          className="d-none"
                          onClick={() =>
                            localStorage.setItem("geolocation", provincia)
                          }
                        ></button>
                      </label>
                    </li>
                  ))}
                </ul>
              </div>

              {me ? (
                <div class="dropdown mx-md-2 mb-2">
                  <button
                    class="btn btn-outline-dark dropdown-toggle  w-100"
                    style={{ borderRadius: "20px" }}
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <span>{`${me.nombre} ${me.apellido}`}</span>
                  </button>
                  <ul class="dropdown-menu">
                    <li>
                      <button
                        class="btn btn-outline-dark w-100 border-0 text-start"
                        type="button"
                        data-bs-toggle="offcanvas"
                        data-bs-target="#offcanvasTop-membresia"
                        aria-controls="offcanvasTop-membresia"
                      >
                        Membresias
                      </button>
                    </li>
                    <li>
                      <button
                        class="btn btn-outline-dark w-100 border-0 text-start"
                        type="button"
                        data-bs-toggle="offcanvas"
                        data-bs-target="#offcanvasTop-entradas"
                        aria-controls="offcanvasTop-entradas"
                      >
                        Entradas
                      </button>
                    </li>
                    <li>
                      <label className="btn btn-outline-dark w-100 border-0 text-start w-100">
                        Cerrar Sesion
                        <button
                          class="d-none"
                          onClick={(e) => Logout()}
                        ></button>
                      </label>
                    </li>
                  </ul>
                </div>
              ) : (
                <label className="d-block d-md-flex btn border-0  p-0 mx-2">
                  <i class="rounded-pill bi bi-person-circle fs-4"></i>
                  <button
                    className="d-none"
                    name="login"
                    onClick={(e) => onClick(e)}
                  ></button>
                </label>
              )}
            </div>
          </div>
        </div>
      </nav>
      <MembresiaBar data={me} />
      <EntradasBar data={me} />
      <Cookie />
    </div>
  );
};

export default Header;
