import axios from "axios";

//'http://127.0.0.1:8000/
//https://api-test.antesala.com.ar/public/api

const url = process.env.REACT_APP_API_PUBLIC_URL;


let apiAntesala = axios.create({
  baseURL: url
});

apiAntesala.defaults.headers.common["Content-Type"] = "application/json";

apiAntesala.interceptors.request.use(function (config) {
  const token = localStorage.getItem("token_api");
  config.headers.Authorization = token ? `Bearer ${token}` : "";

  return config;
});

export default apiAntesala;