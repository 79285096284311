import { useEffect, useState } from "react";
import Footer from "../components/body/Footer";
import Header from "../components/body/Header";
import Salas from "../page/Salas";
import apiAntesala from "../../config/ConfigPublic";
import { FullMonth } from "../utils/FullMonth";

const SalasController = () => {
  const [filter, setFilter] = useState({
    ["filter[provincia]"]: "Córdoba",
    sort: "-nombre",
    per_page: 50,
  });
  const [salas, setSalas] = useState(null);
  const provincias = ["Córdoba", "Túcuman", "Santa Fe", "Entre Ríos"];
  const sorts = [
    {
      name: "Nombre A-Z",
      sort: "nombre",
    },
    {
      name: "Nombre Z-A",
      sort: "-nombre",
    },
  ];

  const fetch = async (setData, params, url) => {
    const response = await apiAntesala.get(url, { params });
    if (response.status == 201 || response.status == 200) {
      setData(response.data);
    }
  };

  useEffect(() => {
    if (!salas) {
      let params = {
        ...filter,
      };
      return fetch(setSalas, params, "/salas");
    }
  }, []);

  console.log({ salas });
  return (
    <div className="container-fluid px-0 bg-amarillo">
      <div className="w-100 shadow-sm mb-1 fixed-top bg-white">
        <Header />
      </div>

      <div className="container-md min-vh-100">
        <Salas 
        provincias={provincias}
        fetch={fetch}
        setSalas={setSalas}
        filter={filter}
        setFilter={setFilter}
        data={salas ?? { data: null }} />
      </div>

      <div className="container-md min-vh-100"></div>
      <div className="w-100 container-fluid px-0 mx-0">
        <Footer />
      </div>
    </div>
  );
};

export default SalasController;
