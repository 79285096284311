import axios from "axios";

//'https://antesala.com.ar/backend/public'
//'http://127.0.0.1:8000/

const url = process.env.REACT_APP_API_OLD_URL;

let clientAxios = axios.create({
  //PRO https://antesala.com.ar/backend/public '
  //DEV https://test.antesala.com.ar/backend/public '
  baseURL: url
});

clientAxios.defaults.headers.common["Content-Type"] = "application/json";

clientAxios.interceptors.request.use(function (config) {
  const token = localStorage.getItem("token");
  const adress = localStorage.getItem("adress");
  config.headers.Authorization = token ? `Bearer ${token}` : "";
  config.headers.adress = adress ? `${adress}` : "";

  return config;
});

export default clientAxios;
