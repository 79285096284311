import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { contextProvider } from "../../../context/ContextProvider";
import Alerts from "../../components/Alerts/Alerts";
import Login from "../../page/login/Login";
import RestApi from "../../../helpers/RestApi";
import clienteAntesala from "../../../config/Config";

const LoginController = () => {
  const navigation = useNavigate();
  const context = useContext(contextProvider);
  const { FORMs, VALIDATIONs, URLs } = context;

  const { URL_LOGIN, URL_ME } = URLs;
  const { FORM_LOGIN } = FORMs;
  const { VALIDATION_FORM_LOGIN } = VALIDATIONs;

  const [form, setForm] = useState(JSON.parse(JSON.stringify(FORM_LOGIN)));
  const [validation, setValidation] = useState(
    JSON.parse(JSON.stringify(VALIDATION_FORM_LOGIN))
  );

  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(true);

  const onClick = async (e, value) => {
    switch (e.target.name) {
      case "show":
        {
          e.preventDefault();
          setShowPassword(!showPassword);
        }
        break;
      case "login":
        {
          let error = false;
          let validations = validation;

          if (form.email[0].trim() == "") {
            error = true;

            validations.email.msg = "Campo obligatorio";
            validations.email.validate = true;
          }

          if (form.password[0].trim() == "") {
            error = true;

            validations.password.msg = "Campo obligatorio";
            validations.password.validate = true;
          }

          setValidation({
            ...validation,
          });

          if (error) return "";

          /* Iniciar sesion */
          let dataForm = new FormData();
          dataForm.append("email", form.email[0]);
          dataForm.append("password", form.password[0]);

          const response = await RestApi({
            method: "post",
            url: URL_LOGIN,
            formData: dataForm,
            setLoading: setLoading,
          });

          if (response.status == 201 || response.status == 200) {
            localStorage.setItem("token", response.data.data);
            const me = await clienteAntesala.get("/auth/me", {});

            Alerts({
              msg: "Alert",
              title: response.data.msg,
              code: "401",
              type: "success",
            });

            if (me.status == 201 || me.status == 200) {
              let rol = me.data.roles[0];
              if (rol.id == 2) {
                return navigation("/");
              }

              if (rol.id == 1 || rol.id == 3 || rol.id == 4) {
                return navigation("/dashboard/micuenta");
              }
            }

            //
          } else {
            console.log(response);

            if (typeof response.data.msg === "string") {
              return Alerts({
                msg: "Alert",
                title: response.data.msg,
                code: "401",
                type: "error",
              });
            }
            Object.keys(response.data.msg).map((key) => {
              return Alerts({
                msg: "Alert",
                title: response.data.msg[key][0],
                code: "401",
                type: "error",
              });
            });
          }
        }
        break;
    }
  };

  const onChange = (e, value) => {
    switch (e.target.name) {
      case "password":
      case "email":
        {
          setValidation(JSON.parse(JSON.stringify(VALIDATION_FORM_LOGIN)));

          let setValue = form;
          setValue[e.target.name][0] = e.target.value;

          return setForm({
            ...form,
            [e.target.name]: setValue[e.target.name],
          });
        }
        break;
    }
  };
  return (
    <Login
      navigation={navigation}
      onClick={onClick}
      onChange={onChange}
      setShowPassword={setShowPassword}
      showPassword={showPassword}
      form={form}
      setForm={form}
      validation={validation}
    ></Login>
  );
};

export default LoginController;
