import Evento from "../components/eventos/Evento";
import Filtro from "../components/eventos/Filtro";
import CardPlaceholder from "../components/load/CardPlaceholder";
import EventoPlaceholder from "../components/load/EventoPlaceholder";

const Entradas = ({
  provincias,
  data,
  setFilter,
  filter,
  sorts,
  fetch,
  setEventos,
  setSalas,
  salas,
  categorias,
}) => {
  return (
    <main className="m-0 p-0">
      <section
        id="eventos"
        style={{ paddingTop: "120px", paddingBottom: "50px" }}
      >
        <div className="container-md">
          <div
            className="offcanvas offcanvas-start"
            data-bs-scroll="true"
            data-bs-backdrop="false"
            tabindex="-1"
            id="offcanvasScrolling"
            aria-labelledby="offcanvasScrollingLabel"
          >
            <div className="offcanvas-header ">
              <h5 className="offcanvas-title" id="offcanvasScrollingLabel"></h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              ></button>
            </div>
            <div className="offcanvas-body">
              <Filtro
                provincias={provincias}
                filter={filter}
                setFilter={setFilter}
                fetch={fetch}
                setEventos={setEventos}
                setSalas={setSalas}
                salas={salas}
                categorias={categorias}
              />
            </div>
          </div>

          <div className="row">
            <div
              className="col-md-3 d-none d-md-block"
              style={{ width: "400px" }}
            >
              <Filtro
                provincias={provincias}
                filter={filter}
                setFilter={setFilter}
                fetch={fetch}
                setEventos={setEventos}
                setSalas={setSalas}
                salas={salas}
                categorias={categorias}
              />
            </div>

            <div className="col-md-8">
              <div className="row">
                <div className="col-12 d-flex justify-content-end">
                  <div className="d-flex align-items-center me-4 wow bounceInUp d-block d-md-none">
                    <label className="btn bg-naranja text-white">
                      Filtrar
                      <button
                        className="d-none"
                        type="button"
                        data-bs-toggle="offcanvas"
                        data-bs-target="#offcanvasScrolling"
                        aria-controls="offcanvasScrolling"
                      ></button>
                    </label>
                  </div>
                  <div className="d-flex align-items-center me-4 wow bounceInUp">
                    <p className="mb-0 me-2 text-nowrap">Ordenar Por</p>
                    <select
                      className="form-select form-select form-select-sm"
                      onChange={(e) =>
                        setFilter({ ...filter, sort: e.target.value })
                      }
                    >
                      <option selected>Seleccionar</option>
                      {sorts.map((sort, index) => {
                        return <option value={sort.sort}>{sort.name}</option>;
                      })}
                    </select>
                  </div>
                </div>
                <div className="col-12">
                  <hr className="wow bounceInUp" />
                </div>
                {data?.data
                  ? data?.data.map((evento, index) => (
                      <div className="col-md-6 col-lg-4 mb-3">
                        <Evento data={evento} key={index} />
                      </div>
                    ))
                  : [1, 2, 3, 4, 6, 7, 8, 9, 10].map(() => (
                      <div className="col-md-6 col-lg-4 mb-3">
                        <EventoPlaceholder />
                      </div>
                    ))}
                <div className="d-grid gap-2 wow bounceInUp">
                  <label
                    className={`btn btn-custom-amarillo ${
                      data?.data
                        ? data?.to == data?.total
                          ? "d-none"
                          : null
                        : "d-none"
                    }`}
                  >
                    ver más +
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default Entradas;
