const EntradasBar = ({ data }) => {
  return (
    <div
      className="offcanvas offcanvas-start"
      tabindex="-1"
      id="offcanvasTop-entradas"
      aria-labelledby="offcanvasTopLabel"
    >
      <div className="offcanvas-header py-1" style={{ height: "20px" }}>
        <h5
          className="offcanvas-title Grifter"
          id="offcanvasTopLabel-entradas"
          style={{ fontSize: "12px" }}
        >
          Entradas Adquiridas
        </h5>
        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        ></button>
      </div>
      <div className="offcanvas-body">
        <div className="row border p-3" style={{ borderRadius: "20px" }}>
          <div className="col-md-6">
            <span className="Grifter">evento de prueba</span>
            <span className="d-block Grifter" style={{ fontSize: "10px" }}>
              lunes, 16 de enero de 2023 HORA: 10:00
            </span>
            <span className="d-block Grifter" style={{ fontSize: "12px" }}>
              Generales x1
            </span>
          </div>
          <div className="col-md-6 text-center">
            <img
              src="/assets/img/eventos/imageNotFound.svg"
              className="w-100"
              alt=""
            />
            <span className="d-block Grifter">gTlY6Y612121</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EntradasBar;
