import { Link } from "react-router-dom";
import Input from "../../components/custom/forms/Input";

const Login = ({
  navigation,
  onClick,
  onChange,
  setShowPassword,
  showPassword,

  form,
  setForm,
  validation,
}) => {
  return (
    <section class="min-vh-100" style={{ backgroundColor: "#eaeaea" }}>
      <div class="container py-5 h-100">
        <div class="row d-flex justify-content-center align-items-center h-100">
          <div class="col col-xl-10">
            <div class="bg-light shadow-sm" style={{ borderRadius: "1rem" }}>
              <div class="row g-0">
                <div class="col-md-6 col-lg-5 d-none d-md-block">
                  <img
                    src="/assets/images/login.svg"
                    alt="login form"
                    class="img-fluid w-100 h-100"
                    style={{ borderRadius: "1rem 0 0 1rem" }}
                  />
                </div>
                <div class="col-md-6 col-lg-7 d-flex align-items-center">
                  <div class="card-body p-4 p-lg-5 text-black">
                    <form className="mb-3">
                      <div class="d-flex align-items-center mb-2 pb-1">
                        <span
                          class="h1 fw-bold mb-0"
                          style={{ width: "100px" }}
                        >
                          <img
                            src="/assets/img/marca/antesala-logo.svg"
                            className="img-fluid"
                            alt=""
                          />
                        </span>
                      </div>

                      <h3
                        class="fw-normal mb-3 pb-3"
                        style={{ letterSpacing: "1px" }}
                      ></h3>

                      <div class="mb-4">
                        <Input
                          onChange={onChange}
                          label={"Email"}
                          name={"email"}
                          type={"text"}
                          value={form.email[0]}
                          svg={
                            ' <svg width="10" height="10" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_3124_63597)"><path d="M8.08541 14.5625C9.96177 14.5625 11.7613 13.8711 13.0881 12.6404C14.4148 11.4097 15.1602 9.74048 15.1602 8C15.1602 6.25952 14.4148 4.59032 13.0881 3.35961C11.7613 2.1289 9.96177 1.4375 8.08541 1.4375C6.20906 1.4375 4.40956 2.1289 3.08277 3.35961C1.75599 4.59032 1.01061 6.25952 1.01061 8C1.01061 9.74048 1.75599 11.4097 3.08277 12.6404C4.40956 13.8711 6.20906 14.5625 8.08541 14.5625ZM8.08541 15.5C5.94101 15.5 3.88443 14.7098 2.36811 13.3033C0.851788 11.8968 -7.24792e-05 9.98912 -7.24792e-05 8C-7.24792e-05 6.01088 0.851788 4.10322 2.36811 2.6967C3.88443 1.29018 5.94101 0.5 8.08541 0.5C10.2298 0.5 12.2864 1.29018 13.8027 2.6967C15.319 4.10322 16.1709 6.01088 16.1709 8C16.1709 9.98912 15.319 11.8968 13.8027 13.3033C12.2864 14.7098 10.2298 15.5 8.08541 15.5V15.5Z" fill="white" /><path d="M7.14564 6.67625L9.46012 6.94531L9.54299 7.30156L9.08818 7.37938C8.79104 7.445 8.73242 7.54438 8.79711 7.81906L9.54299 11.0703C9.73906 11.9113 9.43687 12.3069 8.72636 12.3069C8.17553 12.3069 7.53577 12.0706 7.2457 11.7463L7.15676 11.3563C7.3589 11.5213 7.65402 11.5869 7.85009 11.5869C8.12803 11.5869 8.2291 11.4059 8.15734 11.0872L7.14564 6.67625ZM7.0749 4.71875C7.0749 4.96739 7.18138 5.20585 7.37092 5.38166C7.56046 5.55748 7.81753 5.65625 8.08558 5.65625C8.35363 5.65625 8.6107 5.55748 8.80025 5.38166C8.98979 5.20585 9.09627 4.96739 9.09627 4.71875C9.09627 4.47011 8.98979 4.23165 8.80025 4.05584C8.6107 3.88002 8.35363 3.78125 8.08558 3.78125C7.81753 3.78125 7.56046 3.88002 7.37092 4.05584C7.18138 4.23165 7.0749 4.47011 7.0749 4.71875Z" fill="white" /> </g><defs><clipPath id="clip0_3124_63597"> <rect width="16.171" height="15" fill="white" transform="matrix(-1 0 0 1 16.1709 0.5)" /> </clipPath> </defs></svg>'
                          }
                          validate={validation.email}
                          disabled={false}
                        />
                      </div>

                      <div class="mb-4">
                        <label class="form-label" for="form2Example27">
                          Contraseña
                        </label>
                        <div class="input-group mb-3">
                          <label className="input-group-text  border-0   px-3">
                            {!showPassword ? (
                              <i className="bi bi-eye"></i>
                            ) : (
                              <i className="bi bi-eye-slash"></i>
                            )}
                            <button
                              className="d-none"
                              id="basic-addon1"
                              name="show"
                              onClick={(event) => onClick(event)}
                            ></button>
                          </label>
                          <input
                            type={!showPassword ? "text" : "password"}
                            class="form-control"
                            aria-label="Password"
                            name="password"
                            value={form.password[0]}
                            onChange={(event) => onChange(event)}
                          />
                        </div>

                        {validation.password.status ? (
                          <span className="text-danger">
                            {validation.password.msg}
                          </span>
                        ) : (
                          ""
                        )}
                      </div>

                      <div class="pt-1 mb-4">
                        <label class="btn btn-dark btn-lg btn-block me-1">
                          Login
                          <button
                            className="d-none"
                            type="button"
                            name="login"
                            onClick={(event) => onClick(event)}
                          ></button>
                        </label>

                        <label class="d-none btn btn-secondary btn-lg btn-block">
                          Entrar como invitado
                          <button
                            className="d-none"
                            type="button"
                            name="guest"
                            onClick={(event) => onClick(event)}
                          ></button>
                        </label>
                      </div>
                    </form>

                    <a
                      class="small text-muted"
                      onClick={(event) => navigation("/v/restaurar")}
                    >
                      ¿Olvidaste tu contraseña?
                    </a>
                    <p class="mb-5 pb-lg-2" style={{ color: "#393f81" }}>
                      ¿Todavia no tienes una cuenta?{" "}
                      <Link
                        to="/registro/2"
                        style={{ color: "#393f81" }}
                        onClick={(event) => navigation("/v/registro/1")}
                      >
                        Registrarse.
                      </Link>
                    </p>

                    <Link
                      to="/terminos-y-condiciones"
                      class="small text-muted"
                      onClick={(event) => navigation("/TYP")}
                    >
                      Terminos y condiciones.
                    </Link>

                    {validation.error.status ? (
                      <div className="text-danger fw-bold alert alert-danger ">
                        {validation.error.msg}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Login;
