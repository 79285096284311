import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Completado = (reporte) => {
  const navigate = useNavigate();
  console.log(reporte);

  useEffect(() => {
    let time = 30;
    let interval = setInterval(() => {
      time = time - 1;
      document.getElementById("time-redireccion").innerHTML = time;
      if (time == 0) {
        clearInterval(interval);
        navigate('/');
      }
    }, 1000);
  }, []);
  return (
    <div className="container-md min-vh-100 py-5">
      <div className="py-5">
        <img
          src="/assets/img/marca/antesala-logo.svg"
          className="mb-5"
          width="200px"
          alt=""
        />
        <h2 className="GothamBold fs-3">Gracias por tu compra!</h2>
        <h2 className="GothamBold fs-3">Su pago se completo con exito</h2>
        <span className="GothamBold mb-2 d-block">
          Le enviaremos un correo electronico con las entradas y un codigo de
          aprobación. por favor no comparta el codigo de entrada con ninguna
          otra persona.
        </span>
        <span className="GothamBold mb-2 d-block">
          Si no recibe las entradas en su bandeja de entrada puede revisar en la
          bandeja de spam o contacte directamente con nuestro soporte en
          antesala.cordoba@gmail.com.
        </span>
      </div>
      <span className="GothamBold fs-4">
        <i class="bi bi-arrow-90deg-left me-1"></i> Se rediccionara a la web
        principal en
        <span className="ms-2" id="time-redireccion"></span>
      </span>
    </div>
  );
};

export default Completado;
