import { useEffect, useState } from "react";
import { PATH_SALA_PRODUCTION } from "../../../const/PATH";
import { EventoSlug } from "../../utils/EventoSlug";
import { Link } from "react-router-dom";

const Sala = ({ data }) => {
  const [image, setImage] = useState(
    `${PATH_SALA_PRODUCTION}${data?.imagen_logo}`
  );

  const manejarError = () => {
    setImage("/assets/img/eventos/imageNotFound.svg");
  };

  useEffect(() => {
    if (data) {
      setImage(`${PATH_SALA_PRODUCTION}${data?.imagen_logo}`);
    }
  }, [data]);
  return (
    <Link
      to={`/sala/${EventoSlug(data?.nombre, data?.id)}`}
      className="text-dark text-center d-flex flex-column align-items-center mb-4 p-4"
    >
      <div
        className="overflow-hidden bg-white"
        style={{
          width: "200px",
          height: "200px",
          padding: "30px",
          borderRadius: "20px",
        }}
      >
        <img src={image} className="img-fluid" alt=""
          onError={manejarError} />
      </div>
      <p className="mt-2 mb-0 text-dark">
        <span className="Grifter text-dark fs-6">{data?.provincia}</span> |{" "}
        {data?.localidad}
      </p>
      <h3 className="fs-5">{data?.nombre}</h3>
    </Link>
  );
};

export default Sala;
