export const ValidarMembresia = (membresia, entradas) => {
    if(membresia.limit <= 0){
        return false;
    }

    let cantidadTotal = 0;
    entradas.map((entrada) => {
        if(entrada.quantity){
            cantidadTotal = parseInt(entrada.quantity) + cantidadTotal;
        }
    })

    if(membresia.limit < cantidadTotal){
        return false;
    }

    return true;
}