import { useEffect, useState } from "react";
import Nota from "../components/notas/Nota";
import { PATH_NOTA_PRODUCTION } from "../../const/PATH";
import { format } from "date-fns";
import { es } from "date-fns/locale";
import CardPlaceholder from "../components/load/CardPlaceholder";
import NotaPlaceholder from "../components/load/NotaPlaceholder";
import { EventoSlug } from "../utils/EventoSlug";
import { Link } from "react-router-dom";

const Notas = ({ nota, notas }) => {
  const [image, setImage] = useState(`${PATH_NOTA_PRODUCTION}${nota?.imagen}`);

  const manejarError = () => {
    setImage("/assets/img/eventos/imageNotFound.svg");
  };

  useEffect(() => {
    if (nota) {
      setImage(`${PATH_NOTA_PRODUCTION}${nota?.imagen}`);
    }
  }, [nota]);
  return (
    <div
      className="w-100"
      style={{ paddingTop: "120px", paddingBottom: "50px" }}
    >
      <div className="row">
        <div className="col-12 col-md-12 w-100 mb-3">
          {nota ? (
            <div
              className="row w-100 p-0 m-0 bg-light overflow-hidden"
              style={{ borderRadius: "20px" }}
            >
              <div
                className="text-dark px-3 py-4 border-0 col-md-8 col-12"
                style={{
                  bottom: "0px",
                  borderRadius: "0px 0px 0px 0px",
                }}
              >
                <div className="mb-2">
                  <span className="fs-7 mb-2 text-dark d-block GothamLight">
                    {format(
                      new Date(nota.created_at ?? null),
                      "eeee, d 'de' MMMM 'de' yyyy",
                      {
                        locale: es,
                      }
                    ).toString()}
                  </span>
                  <span className="fs-5 GothamBold text-uppercase ">
                    {nota.titulo}
                  </span>
                </div>
                <p className="fs-8 GothamLight lh-1">{nota.descripcion}</p>
                <Link
                  to={`/contenidos/notas/${EventoSlug(
                    nota.titulo,
                    nota?.id
                  )}`}
                  className="d-block py-2 btn px-2 text-dark border-0"
                  style={{ borderRadius: "20px" }}
                >
                  <span className="d-flex fs-8 GothamLight justify-content-start items-align-center">
                    Leé la nota completa{" "}
                    <i className="ms-2 bi bi-arrow-right fs-6"></i>
                  </span>
                </Link>
              </div>

              <div className="col-12 col-md-4 p-0 overflow-hidden d-flex justify-content-center">
                <img src={image} width={"100%"} alt="" onError={manejarError} />
              </div>
            </div>
          ) : (
            <div
              className="row bg-light p-0 m-0 overflow-hidden"
              style={{ borderRadius: "20px" }}
            >
              <div
                className="text-dark px-3 py-4 border-0 col-md-8"
                style={{
                  bottom: "0px",
                  borderRadius: "0px 0px 0px 0px",
                }}
              >
                <div className="mb-2">
                  <span className="fs-7 mb-2 text-dark d-block GothamLight">
                    <div
                      className="w-100 box-evento-hover border overflow-hidden border placeholder-wave"
                      style={{ borderRadius: "0px", width: "100%" }}
                      aria-hidden="true"
                    >
                      <span
                        className="placeholder w-100 d-block"
                        style={{ height: "15px", background: "#c6c6c6" }}
                      ></span>
                    </div>
                  </span>
                  <span className="fs-5 GothamBold text-uppercase "></span>
                </div>

                <p className="card-text placeholder-wave">
                  <span
                    className="placeholder col-7"
                    style={{ background: "#c6c6c6" }}
                  ></span>
                  <span
                    className="placeholder col-4"
                    style={{ background: "#c6c6c6" }}
                  ></span>
                  <span
                    className="placeholder col-4"
                    style={{ background: "#c6c6c6" }}
                  ></span>
                  <span
                    className="placeholder col-6"
                    style={{ background: "#c6c6c6" }}
                  ></span>
                  <span
                    className="placeholder col-8"
                    style={{ background: "#c6c6c6" }}
                  ></span>
                </p>
                <a
                  href="#"
                  className="d-block  text-dark border-0"
                  style={{ borderRadius: "20px" }}
                >
                  <span className="d-flex fs-8 GothamLight justify-content-start items-align-center">
                    <div
                      className="w-100 box-evento-hover border overflow-hidden border placeholder-wave"
                      style={{ borderRadius: "0px", width: "100%" }}
                      aria-hidden="true"
                    >
                      <span
                        className="placeholder w-100 d-block"
                        style={{ height: "15px", background: "#c6c6c6" }}
                      ></span>
                    </div>
                  </span>
                </a>
              </div>

              <div className="col-md-4 overflow-hidden p-0 m-0">
                <div
                  className="w-100 h-100 box-evento-hover border overflow-hidden border placeholder-wave"
                  style={{
                    borderRadius: "20px",
                  }}
                  aria-hidden="true"
                >
                  <span
                    className="placeholder w-100 h-100 d-block"
                    style={{ background: "#c6c6c6" }}
                  ></span>
                </div>
              </div>
            </div>
          )}
        </div>
        {notas.data
          ? notas?.data.map((nota, index) => {
              if (index > 0) {
                return (
                  <div className="col-6 col-md-4 mb-3 p-2" key={index}>
                    <Nota data={nota} completa={true} />
                  </div>
                );
              }
            })
          : [1, 2, 3, 4, 5, 6, 8, 9, 10, 11, 12, 13, 14, 15].map((d, index) => {
              return (
                <div className="col-6 col-md-4 mb-3 p-2" key={index}>
                  <NotaPlaceholder />
                </div>
              );
            })}
      </div>
    </div>
  );
};

export default Notas;
