import { useEffect, useState } from "react";
import apiAntesala from "../../config/ConfigPublic";
import Header from "../components/body/Header";
import Footer from "../components/body/Footer";
import Notas from "../page/Notas";

export const NotasController = () => {
  const [notas, setNotas] = useState(null);
  const [filter, setFilter] = useState({
    per_page: 30,
  });

  const fetch = async (setData, params, url) => {
    const response = await apiAntesala.get(url, { params });
    if (response.status == 201 || response.status == 200) {
      setData(response.data);
    }
  };

  useEffect(() => {
    if (!notas) {
      let params = {
        ...filter,
      };
      return fetch(setNotas, params, "/notas");
    }
  }, []);

  return (
    <div className="container-fluid px-0 bg-amarillo">
      <div className="w-100 shadow-sm mb-1 fixed-top bg-white">
        <Header discount={false} />
      </div>

      <div className="container-md min-vh-100 ">
        <Notas nota={notas?.data[0] ?? null } notas={notas ?? {data: null}} />
      </div>
      <div className="w-100 container-fluid px-0 mx-0">
        <Footer />
      </div>
    </div>
  );
};
