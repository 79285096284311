import { useContext } from "react";
import Input from "../../../components/custom/forms/Input";
import { contextProvider } from "../../../../context/ContextProvider";
import { useDispatch } from "react-redux";
import { fetchSearchSala, setSearchSala } from "../../../models/salas/Index";
import InputSearchMini from "../../../components/custom/forms/InputSearchMini";

const Donde = ({ setForm, form, setValidation, validation, search }) => {
  const dispatch = useDispatch();
  const context = useContext(contextProvider);
  const { FORMs, VALIDATIONs, URLs } = context;

  const { VALIDATION_FORM_EVENTO, VALIDATION_FORM_EVENTO_SALA } = VALIDATIONs;
  const { FORM_EVENTO_SALA, FORM_EVENTO } = FORMs;
  const { URL_SALA_SEARCH } = URLs;

  const onClick = (e, value) => {
    switch (e.target.name) {
      case "añadir-sala":
        {
          let input = {
            id: [value.id, "required"],
            evento_id: [null, "required"],
            nombre: [value.nombre, "required"],
            razon_social: [value.razon_social, ""],
            email: [value.email, "required"],
            telefono: [value.telefono, "required"],
            direccion: [value.direccion, "required"],
            localidad: [value.localidad, "required"],
            provincia: [value.provincia, "required"],
          };

          setForm({
            ...form,
            sala: [input, "required"],
          });

          return dispatch(setSearchSala([]));
        }
        break;
    }
  };

  const onChange = (e, value) => {
    switch (e.target.name) {
      case "search":
        {
          //form
          let input = form;

          //
          let params = {
            url: URL_SALA_SEARCH,
            search: e.target.value,
          };

          if (
            e.target.value.length > 0 &&
            e.target.value.length > input["sala-search"][0].length
          ) {
            dispatch(fetchSearchSala(params));
          }

          if (e.target.value.trim() == "") {
            dispatch(setSearchSala([]));
          }

          setForm({
            ...form,
            ["sala-search"]: [e.target.value, ""],
          });
        }
        break;
    }
  };

  return (
    <div className="container-md  w-100">
      <div
        className=" shadow-sm  p-3 mb-2"
        style={{ borderRadius: "1rem" }}
      >
        <div className="row">
          <div className="col-md-12 mb-3 px-1">
            <h5 className="">Datos del sitio</h5>
            <span>
              Ingresa los datos del sitio en donde se realizara el evento.
            </span>

            <hr />
            <InputSearchMini
              onChange={onChange}
              label={""}
              name={"search"}
              type={"text"}
              value={form["sala-search"][0]}
              //Autocomplete
              onClick={onClick}
              nameAutocomplete="añadir-sala"
              valueAutocomplete={search}
              showIndex="nombre"
              sendIndex="id"
              validate={validation.sala}
            />
            <label className="btn bg-trasnparent text-dark">
              Agregar nuevo <i class="bi bi-plus-lg ms-2"></i>
              <button className="d-none"></button>
            </label>
          </div>
        </div>
        {form.sala[0] ? (
          <div className="row">
            <div className="col-md-6">
              <Input
                onChange={onChange}
                label={"Nombre"}
                name={"nombre"}
                type={"text"}
                value={form.sala[0].nombre[0]}
                svg={
                  ' <svg width="10" height="10" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_3124_63597)"><path d="M8.08541 14.5625C9.96177 14.5625 11.7613 13.8711 13.0881 12.6404C14.4148 11.4097 15.1602 9.74048 15.1602 8C15.1602 6.25952 14.4148 4.59032 13.0881 3.35961C11.7613 2.1289 9.96177 1.4375 8.08541 1.4375C6.20906 1.4375 4.40956 2.1289 3.08277 3.35961C1.75599 4.59032 1.01061 6.25952 1.01061 8C1.01061 9.74048 1.75599 11.4097 3.08277 12.6404C4.40956 13.8711 6.20906 14.5625 8.08541 14.5625ZM8.08541 15.5C5.94101 15.5 3.88443 14.7098 2.36811 13.3033C0.851788 11.8968 -7.24792e-05 9.98912 -7.24792e-05 8C-7.24792e-05 6.01088 0.851788 4.10322 2.36811 2.6967C3.88443 1.29018 5.94101 0.5 8.08541 0.5C10.2298 0.5 12.2864 1.29018 13.8027 2.6967C15.319 4.10322 16.1709 6.01088 16.1709 8C16.1709 9.98912 15.319 11.8968 13.8027 13.3033C12.2864 14.7098 10.2298 15.5 8.08541 15.5V15.5Z" fill="white" /><path d="M7.14564 6.67625L9.46012 6.94531L9.54299 7.30156L9.08818 7.37938C8.79104 7.445 8.73242 7.54438 8.79711 7.81906L9.54299 11.0703C9.73906 11.9113 9.43687 12.3069 8.72636 12.3069C8.17553 12.3069 7.53577 12.0706 7.2457 11.7463L7.15676 11.3563C7.3589 11.5213 7.65402 11.5869 7.85009 11.5869C8.12803 11.5869 8.2291 11.4059 8.15734 11.0872L7.14564 6.67625ZM7.0749 4.71875C7.0749 4.96739 7.18138 5.20585 7.37092 5.38166C7.56046 5.55748 7.81753 5.65625 8.08558 5.65625C8.35363 5.65625 8.6107 5.55748 8.80025 5.38166C8.98979 5.20585 9.09627 4.96739 9.09627 4.71875C9.09627 4.47011 8.98979 4.23165 8.80025 4.05584C8.6107 3.88002 8.35363 3.78125 8.08558 3.78125C7.81753 3.78125 7.56046 3.88002 7.37092 4.05584C7.18138 4.23165 7.0749 4.47011 7.0749 4.71875Z" fill="white" /> </g><defs><clipPath id="clip0_3124_63597"> <rect width="16.171" height="15" fill="white" transform="matrix(-1 0 0 1 16.1709 0.5)" /> </clipPath> </defs></svg>'
                }
                validate={{ msg: "", status: false }}
                disabled={true}
              />
            </div>
            <div className="col-md-6">
              <Input
                onChange={onChange}
                label={"Dirección"}
                name={"direccion"}
                type={"text"}
                value={form.sala[0].direccion[0]}
                svg={
                  ' <svg width="10" height="10" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_3124_63597)"><path d="M8.08541 14.5625C9.96177 14.5625 11.7613 13.8711 13.0881 12.6404C14.4148 11.4097 15.1602 9.74048 15.1602 8C15.1602 6.25952 14.4148 4.59032 13.0881 3.35961C11.7613 2.1289 9.96177 1.4375 8.08541 1.4375C6.20906 1.4375 4.40956 2.1289 3.08277 3.35961C1.75599 4.59032 1.01061 6.25952 1.01061 8C1.01061 9.74048 1.75599 11.4097 3.08277 12.6404C4.40956 13.8711 6.20906 14.5625 8.08541 14.5625ZM8.08541 15.5C5.94101 15.5 3.88443 14.7098 2.36811 13.3033C0.851788 11.8968 -7.24792e-05 9.98912 -7.24792e-05 8C-7.24792e-05 6.01088 0.851788 4.10322 2.36811 2.6967C3.88443 1.29018 5.94101 0.5 8.08541 0.5C10.2298 0.5 12.2864 1.29018 13.8027 2.6967C15.319 4.10322 16.1709 6.01088 16.1709 8C16.1709 9.98912 15.319 11.8968 13.8027 13.3033C12.2864 14.7098 10.2298 15.5 8.08541 15.5V15.5Z" fill="white" /><path d="M7.14564 6.67625L9.46012 6.94531L9.54299 7.30156L9.08818 7.37938C8.79104 7.445 8.73242 7.54438 8.79711 7.81906L9.54299 11.0703C9.73906 11.9113 9.43687 12.3069 8.72636 12.3069C8.17553 12.3069 7.53577 12.0706 7.2457 11.7463L7.15676 11.3563C7.3589 11.5213 7.65402 11.5869 7.85009 11.5869C8.12803 11.5869 8.2291 11.4059 8.15734 11.0872L7.14564 6.67625ZM7.0749 4.71875C7.0749 4.96739 7.18138 5.20585 7.37092 5.38166C7.56046 5.55748 7.81753 5.65625 8.08558 5.65625C8.35363 5.65625 8.6107 5.55748 8.80025 5.38166C8.98979 5.20585 9.09627 4.96739 9.09627 4.71875C9.09627 4.47011 8.98979 4.23165 8.80025 4.05584C8.6107 3.88002 8.35363 3.78125 8.08558 3.78125C7.81753 3.78125 7.56046 3.88002 7.37092 4.05584C7.18138 4.23165 7.0749 4.47011 7.0749 4.71875Z" fill="white" /> </g><defs><clipPath id="clip0_3124_63597"> <rect width="16.171" height="15" fill="white" transform="matrix(-1 0 0 1 16.1709 0.5)" /> </clipPath> </defs></svg>'
                }
                validate={{ msg: "", status: false }}
                disabled={true}
              />
            </div>
            <div className="col-md-6">
              <Input
                onChange={onChange}
                label={"Email"}
                name={"email"}
                type={"text"}
                value={form.sala[0].email[0]}
                svg={
                  ' <svg width="10" height="10" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_3124_63597)"><path d="M8.08541 14.5625C9.96177 14.5625 11.7613 13.8711 13.0881 12.6404C14.4148 11.4097 15.1602 9.74048 15.1602 8C15.1602 6.25952 14.4148 4.59032 13.0881 3.35961C11.7613 2.1289 9.96177 1.4375 8.08541 1.4375C6.20906 1.4375 4.40956 2.1289 3.08277 3.35961C1.75599 4.59032 1.01061 6.25952 1.01061 8C1.01061 9.74048 1.75599 11.4097 3.08277 12.6404C4.40956 13.8711 6.20906 14.5625 8.08541 14.5625ZM8.08541 15.5C5.94101 15.5 3.88443 14.7098 2.36811 13.3033C0.851788 11.8968 -7.24792e-05 9.98912 -7.24792e-05 8C-7.24792e-05 6.01088 0.851788 4.10322 2.36811 2.6967C3.88443 1.29018 5.94101 0.5 8.08541 0.5C10.2298 0.5 12.2864 1.29018 13.8027 2.6967C15.319 4.10322 16.1709 6.01088 16.1709 8C16.1709 9.98912 15.319 11.8968 13.8027 13.3033C12.2864 14.7098 10.2298 15.5 8.08541 15.5V15.5Z" fill="white" /><path d="M7.14564 6.67625L9.46012 6.94531L9.54299 7.30156L9.08818 7.37938C8.79104 7.445 8.73242 7.54438 8.79711 7.81906L9.54299 11.0703C9.73906 11.9113 9.43687 12.3069 8.72636 12.3069C8.17553 12.3069 7.53577 12.0706 7.2457 11.7463L7.15676 11.3563C7.3589 11.5213 7.65402 11.5869 7.85009 11.5869C8.12803 11.5869 8.2291 11.4059 8.15734 11.0872L7.14564 6.67625ZM7.0749 4.71875C7.0749 4.96739 7.18138 5.20585 7.37092 5.38166C7.56046 5.55748 7.81753 5.65625 8.08558 5.65625C8.35363 5.65625 8.6107 5.55748 8.80025 5.38166C8.98979 5.20585 9.09627 4.96739 9.09627 4.71875C9.09627 4.47011 8.98979 4.23165 8.80025 4.05584C8.6107 3.88002 8.35363 3.78125 8.08558 3.78125C7.81753 3.78125 7.56046 3.88002 7.37092 4.05584C7.18138 4.23165 7.0749 4.47011 7.0749 4.71875Z" fill="white" /> </g><defs><clipPath id="clip0_3124_63597"> <rect width="16.171" height="15" fill="white" transform="matrix(-1 0 0 1 16.1709 0.5)" /> </clipPath> </defs></svg>'
                }
                validate={{ msg: "", status: false }}
                disabled={true}
              />
            </div>
            <div className="col-md-6">
              <Input
                onChange={onChange}
                label={"Provincia"}
                name={"provincia"}
                type={"text"}
                value={form.sala[0].provincia[0]}
                svg={
                  ' <svg width="10" height="10" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_3124_63597)"><path d="M8.08541 14.5625C9.96177 14.5625 11.7613 13.8711 13.0881 12.6404C14.4148 11.4097 15.1602 9.74048 15.1602 8C15.1602 6.25952 14.4148 4.59032 13.0881 3.35961C11.7613 2.1289 9.96177 1.4375 8.08541 1.4375C6.20906 1.4375 4.40956 2.1289 3.08277 3.35961C1.75599 4.59032 1.01061 6.25952 1.01061 8C1.01061 9.74048 1.75599 11.4097 3.08277 12.6404C4.40956 13.8711 6.20906 14.5625 8.08541 14.5625ZM8.08541 15.5C5.94101 15.5 3.88443 14.7098 2.36811 13.3033C0.851788 11.8968 -7.24792e-05 9.98912 -7.24792e-05 8C-7.24792e-05 6.01088 0.851788 4.10322 2.36811 2.6967C3.88443 1.29018 5.94101 0.5 8.08541 0.5C10.2298 0.5 12.2864 1.29018 13.8027 2.6967C15.319 4.10322 16.1709 6.01088 16.1709 8C16.1709 9.98912 15.319 11.8968 13.8027 13.3033C12.2864 14.7098 10.2298 15.5 8.08541 15.5V15.5Z" fill="white" /><path d="M7.14564 6.67625L9.46012 6.94531L9.54299 7.30156L9.08818 7.37938C8.79104 7.445 8.73242 7.54438 8.79711 7.81906L9.54299 11.0703C9.73906 11.9113 9.43687 12.3069 8.72636 12.3069C8.17553 12.3069 7.53577 12.0706 7.2457 11.7463L7.15676 11.3563C7.3589 11.5213 7.65402 11.5869 7.85009 11.5869C8.12803 11.5869 8.2291 11.4059 8.15734 11.0872L7.14564 6.67625ZM7.0749 4.71875C7.0749 4.96739 7.18138 5.20585 7.37092 5.38166C7.56046 5.55748 7.81753 5.65625 8.08558 5.65625C8.35363 5.65625 8.6107 5.55748 8.80025 5.38166C8.98979 5.20585 9.09627 4.96739 9.09627 4.71875C9.09627 4.47011 8.98979 4.23165 8.80025 4.05584C8.6107 3.88002 8.35363 3.78125 8.08558 3.78125C7.81753 3.78125 7.56046 3.88002 7.37092 4.05584C7.18138 4.23165 7.0749 4.47011 7.0749 4.71875Z" fill="white" /> </g><defs><clipPath id="clip0_3124_63597"> <rect width="16.171" height="15" fill="white" transform="matrix(-1 0 0 1 16.1709 0.5)" /> </clipPath> </defs></svg>'
                }
                validate={{ msg: "", status: false }}
                disabled={true}
              />
            </div>
            <div className="col-md-6">
              <Input
                onChange={onChange}
                label={"Telefono"}
                name={"telefono"}
                type={"text"}
                value={form.sala[0].telefono[0]}
                svg={
                  ' <svg width="10" height="10" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_3124_63597)"><path d="M8.08541 14.5625C9.96177 14.5625 11.7613 13.8711 13.0881 12.6404C14.4148 11.4097 15.1602 9.74048 15.1602 8C15.1602 6.25952 14.4148 4.59032 13.0881 3.35961C11.7613 2.1289 9.96177 1.4375 8.08541 1.4375C6.20906 1.4375 4.40956 2.1289 3.08277 3.35961C1.75599 4.59032 1.01061 6.25952 1.01061 8C1.01061 9.74048 1.75599 11.4097 3.08277 12.6404C4.40956 13.8711 6.20906 14.5625 8.08541 14.5625ZM8.08541 15.5C5.94101 15.5 3.88443 14.7098 2.36811 13.3033C0.851788 11.8968 -7.24792e-05 9.98912 -7.24792e-05 8C-7.24792e-05 6.01088 0.851788 4.10322 2.36811 2.6967C3.88443 1.29018 5.94101 0.5 8.08541 0.5C10.2298 0.5 12.2864 1.29018 13.8027 2.6967C15.319 4.10322 16.1709 6.01088 16.1709 8C16.1709 9.98912 15.319 11.8968 13.8027 13.3033C12.2864 14.7098 10.2298 15.5 8.08541 15.5V15.5Z" fill="white" /><path d="M7.14564 6.67625L9.46012 6.94531L9.54299 7.30156L9.08818 7.37938C8.79104 7.445 8.73242 7.54438 8.79711 7.81906L9.54299 11.0703C9.73906 11.9113 9.43687 12.3069 8.72636 12.3069C8.17553 12.3069 7.53577 12.0706 7.2457 11.7463L7.15676 11.3563C7.3589 11.5213 7.65402 11.5869 7.85009 11.5869C8.12803 11.5869 8.2291 11.4059 8.15734 11.0872L7.14564 6.67625ZM7.0749 4.71875C7.0749 4.96739 7.18138 5.20585 7.37092 5.38166C7.56046 5.55748 7.81753 5.65625 8.08558 5.65625C8.35363 5.65625 8.6107 5.55748 8.80025 5.38166C8.98979 5.20585 9.09627 4.96739 9.09627 4.71875C9.09627 4.47011 8.98979 4.23165 8.80025 4.05584C8.6107 3.88002 8.35363 3.78125 8.08558 3.78125C7.81753 3.78125 7.56046 3.88002 7.37092 4.05584C7.18138 4.23165 7.0749 4.47011 7.0749 4.71875Z" fill="white" /> </g><defs><clipPath id="clip0_3124_63597"> <rect width="16.171" height="15" fill="white" transform="matrix(-1 0 0 1 16.1709 0.5)" /> </clipPath> </defs></svg>'
                }
                validate={{ msg: "", status: false }}
                disabled={true}
              />
            </div>
            <div className="col-md-6">
              <Input
                onChange={onChange}
                label={"Localidad"}
                name={"localidad"}
                type={"text"}
                value={form.sala[0].localidad[0]}
                svg={
                  ' <svg width="10" height="10" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_3124_63597)"><path d="M8.08541 14.5625C9.96177 14.5625 11.7613 13.8711 13.0881 12.6404C14.4148 11.4097 15.1602 9.74048 15.1602 8C15.1602 6.25952 14.4148 4.59032 13.0881 3.35961C11.7613 2.1289 9.96177 1.4375 8.08541 1.4375C6.20906 1.4375 4.40956 2.1289 3.08277 3.35961C1.75599 4.59032 1.01061 6.25952 1.01061 8C1.01061 9.74048 1.75599 11.4097 3.08277 12.6404C4.40956 13.8711 6.20906 14.5625 8.08541 14.5625ZM8.08541 15.5C5.94101 15.5 3.88443 14.7098 2.36811 13.3033C0.851788 11.8968 -7.24792e-05 9.98912 -7.24792e-05 8C-7.24792e-05 6.01088 0.851788 4.10322 2.36811 2.6967C3.88443 1.29018 5.94101 0.5 8.08541 0.5C10.2298 0.5 12.2864 1.29018 13.8027 2.6967C15.319 4.10322 16.1709 6.01088 16.1709 8C16.1709 9.98912 15.319 11.8968 13.8027 13.3033C12.2864 14.7098 10.2298 15.5 8.08541 15.5V15.5Z" fill="white" /><path d="M7.14564 6.67625L9.46012 6.94531L9.54299 7.30156L9.08818 7.37938C8.79104 7.445 8.73242 7.54438 8.79711 7.81906L9.54299 11.0703C9.73906 11.9113 9.43687 12.3069 8.72636 12.3069C8.17553 12.3069 7.53577 12.0706 7.2457 11.7463L7.15676 11.3563C7.3589 11.5213 7.65402 11.5869 7.85009 11.5869C8.12803 11.5869 8.2291 11.4059 8.15734 11.0872L7.14564 6.67625ZM7.0749 4.71875C7.0749 4.96739 7.18138 5.20585 7.37092 5.38166C7.56046 5.55748 7.81753 5.65625 8.08558 5.65625C8.35363 5.65625 8.6107 5.55748 8.80025 5.38166C8.98979 5.20585 9.09627 4.96739 9.09627 4.71875C9.09627 4.47011 8.98979 4.23165 8.80025 4.05584C8.6107 3.88002 8.35363 3.78125 8.08558 3.78125C7.81753 3.78125 7.56046 3.88002 7.37092 4.05584C7.18138 4.23165 7.0749 4.47011 7.0749 4.71875Z" fill="white" /> </g><defs><clipPath id="clip0_3124_63597"> <rect width="16.171" height="15" fill="white" transform="matrix(-1 0 0 1 16.1709 0.5)" /> </clipPath> </defs></svg>'
                }
                validate={{ msg: "", status: false }}
                disabled={true}
              />
            </div>
            <div className="col-md-12">
            <iframe src={"https://maps.google.com/?q="+encodeURI(form.sala[0].direccion[0]+" "+form.sala[0].provincia[0]+" "+form.sala[0].direccion[0])+"&z=14&t=m&output=embed"} width="600" height="450" style={{border: "0"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" className="my-3"></iframe>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default Donde;
