export const CarouselSala = (sala) => {
    let carousel = [];
    if(!sala){
        return carousel;
    }
    carousel[0] = sala['imagen_desc_1'];
    carousel[1] = sala['imagen_desc_2'];
    carousel[2] = sala['imagen_desc_3'];
    carousel[3] = sala['imagen_int_1'];
    carousel[4] = sala['imagen_int_2'];
    carousel[5] = sala['imagen_int_3'];
    return carousel;
}