import { useEffect, useState } from "react";
import Pago from "../page/Pagar";
import apiAntesala from "../../config/ConfigPublic";
import { useDispatch, useSelector } from "react-redux";
import { meState } from "../../web/models/users/Index";
import axios from "axios";
import FingerprintJS from "@fingerprintjs/fingerprintjs";
import { PaymentMethodId } from "../utils/PaymentMethodId";
import restPublicApi from "../../helpers/RestPublicApi";
import Completado from "../page/Completado";

const regex = /^[0-9/]*$/;

const PagoController = () => {
  const dispatch = useDispatch();
  const _me = useSelector(meState);
  const me = _me.me;
  const [visitante, setVisitante] = useState(null);
  const [required, setRequired] = useState({
    debito: false,
    credito: false,
    numero: false,
    titular: false,
    vencimiento: false,
    codigo: false,
    tipo: false,
    focusCVV: false,
    email: false,
    telefono: false,
    dni: false,
  });
  const [info, setInfo] = useState({
    debito: false,
    credito: false,
    dni: "",
    numero: "",
    titular: "",
    vencimiento: "",
    codigo: "",
    tipo: null,
    focusCVV: false,
    email: "",
    telefono: "",
  });
  const [evento, setEvento] = useState(null);
  const [metodoPago, setMetodoPago] = useState(null);
  const [loading, setLoading] = useState(false);
  const [reporte, setReporte] = useState(null);

  const onChange = (e, value) => {
    switch (e.target.name) {
      case "tipo":
        {
          if (e.target.value == "debito") {
            setInfo({ ...info, debito: true, credito: false });
          }

          if (e.target.value == "credito") {
            setInfo({ ...info, debito: false, credito: true });
          }
        }
        break;
      case "vencimiento":
        {
          let value = e.target.value.replace(/\D/g, ""); // Eliminar caracteres no numéricos

          if (value.length >= 2) {
            value = value.slice(0, 2) + "/" + value.slice(2); // Añadir la barra
          }

          if (regex.test(value)) {
            return setInfo({
              ...info,
              [e.target.name]: value,
            });
          }
        }
        break;
      case "dni":
      case "email":
      case "telefono":
      case "titular":
      case "codigo":
        {
          setInfo({
            ...info,
            [e.target.name]: e.target.value,
          });
        }
        break;
      case "numero":
        {
          if (isNaN(e.target.value)) {
            return null;
          }

          let tipo = null;
          if (e.target.value[0] == "4") {
            tipo = "visa";
          }

          if (
            ["51", "52", "53", "54", "55"].includes(
              e.target.value[0] + e.target.value[0]
            )
          ) {
            tipo = "mastercard";
          }

          if (
            ["50", "56", "69"].includes(e.target.value[0] + e.target.value[1])
          ) {
            tipo = "maestro";
          }

          if (["34", "37"].includes(e.target.value[0] + e.target.value[1])) {
            tipo = "amex";
          }

          setInfo({
            ...info,
            [e.target.name]: e.target.value.replace(
              new RegExp(" ".replace(/\./g, "\\."), "gi"),
              ""
            ),
            tipo: tipo,
          });
        }
        break;
      default:
        break;
    }
  };

  const onClick = async (e, value) => {
    switch (e.target.name) {
      case "pagar":
        {
          let _required = required;
          let error = false;
          Object.keys(required).map((key) => {
            if (key == "debito" || key == "credito") {
              _required[key] = info[key];
            }

            if (
              key !== "debito" &&
              key !== "credito" &&
              String(info[key]).trim() == ""
            ) {
              _required[key] = true;
              error = true;
            } else {
              if (key !== "debito" && key !== "credito") {
                _required[key] = false;
              }
            }
          });

          setRequired({ ..._required });
          if (error) {
            return null;
          }

          let formToken = {
            card_number: info.numero,
            card_expiration_month: info.vencimiento.split("/")[0],
            card_expiration_year: info.vencimiento.split("/")[1],
            security_code: info.codigo,
            card_holder_name: info.titular,
            card_holder_identification: {
              type: "dni",
              number: info.dni,
            },
            fraud_detection: {
              device_unique_identifier: visitante,
            },
            ip_address: "192.168.100.1",
          };

          const key = process.env.REACT_APP_PUBLIC_KEY_LOCAL;
          const url = process.env.REACT_APP_URL_LOCAL;

          const response = await axios({
            method: "post",
            url: url,
            headers: {
              apikey: key,
            },
            data: formToken,
          });

          if (response.status == 201) {
            const token = response.data.id;
            const payment_method_id = PaymentMethodId(response.data.bin, info);

            const formdata = new FormData();
            formdata.append("nombre", info.titular);
            formdata.append("email", info.email);
            formdata.append("telefono", info.telefono);
            formdata.append("bin", response.data.bin);
            formdata.append("payment_method", payment_method_id);
            formdata.append("id_evento", evento.evento.id);
            formdata.append("fecha", evento.select.fecha);
            evento.evento.entradas.map((entrada, index) => {
              if (entrada.quantity ?? null) {
                formdata.append("entradas[" + index + "][id]", entrada.id);
                formdata.append(
                  "entradas[" + index + "][quantity]",
                  entrada.quantity
                );
              }
            });
            formdata.append("token", token);

            const result = await restPublicApi({
              url: "/checkout/payment",
              formData: formdata,
              setLoading: setLoading,
              method: "POST",
            });

            if (result.status == 200) {
              setReporte({
                reporte: result.data.data,
              });
            }
          }
        }
        break;
      default:
        break;
    }
  };

  const fetch = async (setData, params, url) => {
    const response = await apiAntesala.get(url, { params });
    if (response.status == 201 || response.status == 200) {
      setData(response.data);
    }
  };

  useEffect(() => {
    if (!evento) {
      let local = localStorage.getItem("entradas");
      if (local) {
        local = JSON.parse(local);
        setEvento(local);
      }
    }
  }, []);

  useEffect(() => {
    if (!metodoPago) {
      fetch(setMetodoPago, {}, "/checkout/metodo-pago");
    }
  }, []);

  useEffect(() => {
    const fetchFingerprint = async () => {
      const fp = await FingerprintJS.load();
      const result = await fp.get();
      setVisitante(result.visitorId);
    };

    fetchFingerprint();
  }, []);

  if (!evento) {
    return "";
  }

  //console.log(me);

  if (reporte) {
    return <Completado reporte={reporte} />;
  }

  return (
    <Pago
      info={info}
      evento={evento}
      setInfo={setInfo}
      onChange={onChange}
      onClick={onClick}
      required={required}
      loading={loading}
    />
  );
};

export default PagoController;
