const Filtro = ({ setFilter, filter, fetch, provincias, setSalas }) => {
  const onChange = (e, value) => {
    switch (e.target.name) {
      case "search":
        {
          if (e.target.value.length == 0) {
            setSalas({ data: null });
            let params = {
              ...filter,
              ["filter[nombre]"]: '',
            };
            return fetch(setSalas, params, "/salas");
          }

          if (e.target.value.length > 3) {
            let params = {
              ...filter,
              ["filter[nombre]"]: e.target.value,
            };

            return fetch(setSalas, params, "/salas");
          }
        }
        break;
      default:
        break;
    }
  };

  const onClick = (e, value) => {
    switch (e.target.name) {
      case "provincia":
        {
          if (!filter.hasOwnProperty("filter[provincia]")) {
            let params = {
              ...filter,
              ["filter[provincia]"]: value,
            };
            setFilter({ ...params });
            setSalas({ data: null });
            return fetch(setSalas, params, "/salas");
          }

          if (
            filter["filter[provincia]"].split(",").length == 1 &&
            filter["filter[provincia]"].indexOf(value) !== -1
          ) {
            let params = filter;
            delete params["filter[provincia]"];
            setFilter({ ...params });
            setSalas({ data: null });
            return fetch(setSalas, params, "/salas");
          }

          if (filter["filter[provincia]"].indexOf(value) !== -1) {
            let params = {
              ...filter,
              ["filter[provincia]"]: filter["filter[provincia]"].replace(
                "," + value,
                ""
              ),
            };

            setFilter({ ...params });
            setSalas({ data: null });
            return fetch(setSalas, params, "/eventos");
          } else {
            let params = {
              ...filter,
              ["filter[provincia]"]: `${filter["filter[provincia]"]},${value}`,
            };

            setFilter({ ...params });
            setSalas({ data: null });
            return fetch(setSalas, params, "/salas");
          }
        }
        break;

      default:
        break;
    }
  };
  return (
    <div className="d-md-flex justify-content-end">
      <div className="mb-4">
        <span
          className="GothamBold mb-0 mb-2 wow bounceInUp"
          style={{ fontSize: "12px" }}
        >
          Ubicación
        </span>
        <ul className="list-group list-group-horizontal">
          {provincias.map((provincia, index) => {
            if (filter.hasOwnProperty("filter[provincia]")) {
              if (filter["filter[provincia]"].includes(provincia)) {
                return (
                  <label
                    className="list-group-item d-flex justify-content-between py-1 w-auto mb-1 me-1 text-nowrap"
                    style={{ borderRadius: "20px", cursor: "pointer" }}
                    key={index}
                  >
                    {`${provincia}`}
                    <i className="bi bi-x-lg ms-2"></i>
                    <button
                      className="d-none"
                      name="provincia"
                      onClick={(e) => onClick(e, provincia)}
                    ></button>
                  </label>
                );
              } else {
                return (
                  <label
                    className="list-group-item border bg-transparent d-flex justify-content-between py-1 w-auto mb-1 me-1 text-nowrap"
                    key={index}
                    style={{ borderRadius: "20px",cursor: "pointer" }}
                  >
                    {`${provincia}`}
                    <button
                      className="d-none"
                      name="provincia"
                      onClick={(e) => onClick(e, provincia)}
                    ></button>
                  </label>
                );
              }
            } else {
              return (
                <label
                  className="list-group-item border bg-transparent d-flex justify-content-between py-1 w-auto mb-1 me-1 text-nowrap"
                  key={index}
                  style={{ borderRadius: "20px",cursor: "pointer" }}
                >
                  {`${provincia}`}
                  <button
                    className="d-none"
                    name="provincia"
                    onClick={(e) => onClick(e, provincia)}
                  ></button>
                </label>
              );
            }
          })}
        </ul>
      </div>

      <div className="mb-3">
        <span
          className="GothamBold mb-0 mb-2 wow bounceInUp"
          style={{ fontSize: "12px" }}
        >
          Buscar
        </span>
        <div className="input-group" style={{ height: "30px" }}>
          <input
            type="text"
            className="form-control"
            name="search"
            onChange={(e) => onChange(e)}
          />
          <span className="input-group-text btn-dark" id="basic-addon2">
            <i className="bi bi-search"></i>
          </span>
        </div>
      </div>
    </div>
  );
};

export default Filtro;
