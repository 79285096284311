import { useEffect, useState } from "react";
import { PATH_NOTA_PRODUCTION } from "../../const/PATH";
import { es } from "date-fns/locale";
import { format } from "date-fns";

const Nota = ({ data }) => {
  const [image, setImage] = useState(`${PATH_NOTA_PRODUCTION}${data?.imagen}`);
  const manejarError = () => {
    setImage("/assets/img/eventos/imageNotFound.svg");
  };

  useEffect(() => {
    if (data) {
      setImage(`${PATH_NOTA_PRODUCTION}${data?.imagen}`);
    }
  }, [data]);
  return (
    <div>
      <section class="bg-light">
        <div
          className="w-100 d-flex justify-content-center align-items-end mb-5"
          style={{
            backgroundImage: `url("${image}")`,
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            height: "600px",
          }}
        >
          <div
            className="bg-white border-0 p-5 w-auto fw-bold fs-3 text-center Grifter"
            style={{
              borderRadius: "5px",
              position: "relative",
              bottom: "-50px",
            }}
          >
            {data?.titulo}
            <p className="fs-6 mt-5" style={{ color: "rgba(0,0,0,0.4)" }}>
              Por {data?.autor}
            </p>
          </div>
        </div>
      </section>
      <section className="container-md " style={{ marginBottom: "150px" }}>
        <div className="fw-bold" style={{ color: "#d0552a" }}>
          _
        </div>
        <div className="d-flex justify-content-start mb-4 Grifter">
          {`${format(new Date(data?.created_at ?? null), "eeee, d 'de' MMMM 'de' yyyy", {
            locale: es,
          }).toString()}`}
        </div>
        <div className="bg-light d-flex justify-content-center mx-auto">
          <div dangerouslySetInnerHTML={{ __html: data?.contenido }}></div>
        </div>
      </section>

      {data ? (
        <section id="noticias" class="mt-100 pt-100 mb-5 d-none">
          <div class="container">
            <div class="row mb-5">
              <div class="col-md-7 d-flex flex-column justify-content-center">
                <p>{data.created_at}</p>
                <hr class="hr-black" />
                <h2 class="fc-amarillo">"{data.titulo}"</h2>
                <p class="Grifter">Por {data.autor}</p>
                <strong>{data.descripcion}</strong>
              </div>
              <div class="col-md-5 text-md-end mt-4 mt-md-0">
                <img src={image} width="80%" alt="" />
              </div>
            </div>
          </div>
        </section>
      ) : (
        ""
      )}

      {data ? (
        <section class="bg-gris-claro pt-100 pb-100 d-none">
          <div class="container">
            <div class="row">
              <div class="col-md-12" style={{ columnCount: "2" }}>
                <div dangerouslySetInnerHTML={{ __html: data.contenido }}></div>
              </div>
            </div>
          </div>
        </section>
      ) : (
        ""
      )}
    </div>
  );
};

export default Nota;
