import { useEffect, useState } from "react";
import Footer from "../components/body/Footer";
import Header from "../components/body/Header";
import Entradas from "../page/Entradas";
import apiAntesala from "../../config/ConfigPublic";
import { FullMonth } from "../utils/FullMonth";
import { useParams } from "react-router-dom";
import { format } from "date-fns";

const EntradasController = () => {
  const {search} = useParams();
  const [filter, setFilter] = useState({
    ["filter[provincia]"]: "Córdoba",
    ["filter[fecha]"]: format(new Date(), 'yyyy-M-dd').toString(),//FullMonth(new Date()),
    sort: "-fechas.fecha",
    per_page: 50,
  });
  const [categorias, setCategorias] = useState(null);
  const [eventos, setEventos] = useState(null);
  const [salas, setSalas] = useState({data: null});
  const provincias = ["Córdoba", "Túcuman", "Santa Fe", "Entre Ríos"];
  const sorts = [
    {
      name: "Menor precio",
      sort: "entradas.precio",
    },
    {
      name: "Mayor precio",
      sort: "-entradas.precio",
    },
    {
      name: "Fecha mas reciente",
      sort: "-fechas.fecha",
    },
  ];

  const fetch = async (setData, params, url) => {
    const response = await apiAntesala.get(url, { params });
    if (response.status == 201 || response.status == 200) {
      setData(response.data);
    }
  };

  useEffect(() => {
    if (!eventos) {
      let params = {
        ...filter,
      };

      if(search){
        params = {
          ...filter,
          ["filter[nombre]"]: search,
        };

        setFilter({...params})
      }

      return fetch(setEventos, params, "/eventos");
    }
  }, []);
  
  useEffect(() => {
    if (!categorias) {
      return fetch(setCategorias, {}, "/categorias");
    }
  }, []);
  return (
    <div className="container-fluid px-0 bg-light">
      <div className="w-100 shadow-sm mb-1 fixed-top bg-white">
        <Header discount={false} />
      </div>

      <div className="container-md min-vh-100">
        <Entradas
          provincias={provincias}
          data={eventos ?? { data: null }}
          setFilter={setFilter}
          filter={filter}
          sorts={sorts}
          fetch={fetch}

          setEventos={setEventos}
          setSalas={setSalas}
          salas={salas}
          categorias={categorias}
        />
      </div>
      <div className="w-100 container-fluid px-0 mx-0">
        <Footer />
      </div>
    </div>
  );
};

export default EntradasController;
