import apiAntesala from "../config/ConfigPublic";


const restPublicApi = async ({
  url,
  formData,
  setLoading,
  method
}) => {
  setLoading(true);

  const response = await apiAntesala({
    method: method,
    url: url,
    data: formData,
  }).then((result) => {
    setLoading(false);
    return result
  }).catch((error) => {
    setLoading(false);
    if (error.response) {
      return error.response;
    } else if (error.request) {
      return error.request;
    } else {
      return error.message;
    }
  })


  return response;
}

export default restPublicApi

