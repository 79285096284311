import { useParams } from "react-router-dom";
import Footer from "../components/body/Footer";
import Header from "../components/body/Header";
import { useEffect, useState } from "react";
import apiAntesala from "../../config/ConfigPublic";
import Sala from "../page/Sala";
import { format } from "date-fns";

const SalaController = () => {
  const { slug } = useParams();
  const [eventos, setEventos] = useState(null);
  const [sala, setSala] = useState(null);
  const [form, setForm] = useState({
    fecha: null,
    stock: 0,
  });

  const fetch = async (setData, params, url) => {
    const response = await apiAntesala.get(url, { params });
    if (response.status == 201 || response.status == 200) {
      setData(response.data);
    }
  };

  useEffect(() => {
    if (!sala) {
      fetch(setSala, {}, `/salas/${slug}`);
    }
  }, []);

  useEffect(() => {
    if (!eventos && sala) {
      let params = {
        ["filter[nombre_sitio]"]: sala?.nombre,
        ["filter[fecha]"]: format(new Date(), 'yyyy-M-dd').toString()
      }
      fetch(setEventos, params, `/eventos`);
    }
  }, [sala]);

  
  return (
    <div className="container-fluid px-0 bg-light">
      <div className="w-100 shadow-sm mb-1 fixed-top bg-white">
        <Header />
      </div>

      <div className="container-md ">
          <Sala sala={sala} eventos={eventos ?? {data: null}}/>
      </div>
      
      <div className="container-md min-vh-100"></div>
      <div className="w-100 container-fluid px-0 mx-0">
        <Footer />
      </div>
    </div>
  );
};

export default SalaController;
