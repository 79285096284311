import { useState } from "react";
import {
  PATH_DESCRIPTION_PRODUCTION,
  PATH_SALA_PRODUCTION,
} from "../../const/PATH";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { CarouselSala } from "../utils/CarouselSala";
import Evento from "../components/eventos/Evento";
import EventoPlaceholder from "../components/load/EventoPlaceholder";

const Sala = ({ sala, eventos }) => {
  return (
    <div className="" style={{ paddingTop: "100px" }}>
      <div className="row py-5 my-5" style={{background: "#eaeaea"}}>
        <div className="col-md-6 d-md-flex d-block">
          <div className="sala-logo wow bounceInUp me-3 overflow-hidden"
          style={{
            width: "200px",
            height: "200px",
            borderRadius: "20px"
          }}>
            <img
              src={`${PATH_SALA_PRODUCTION}${sala?.imagen_logo}`}
              className="w-100"
              alt=""
            />
          </div>
          <div>
            <span className="d-block Grifter text-nowrap fs-5">{`${sala?.provincia}|${sala?.localidad}`}</span>
            <span className="d-block Grifter fc-amarillo fs-3">
              {sala?.nombre}
            </span>
            <span className="d-block">
              {`${sala?.direccion}, ${sala?.provincia}|${sala?.localidad}`}
            </span>
            <span className="d-block">{sala?.email}</span>
            <span className="d-block">{sala?.telefono}</span>
            <a href={sala?.website}>{sala?.nombre}</a>
          </div>
        </div>
        <div className="col-md-6">
          <div className="overflow-hidden w-100">
            <img
              src={`${PATH_DESCRIPTION_PRODUCTION}${sala?.imagen_desc_1}`}
              className="w-100 h-100"
              style={{
                borderRadius: "20px",
              }}
              alt=""
            />
          </div>
        </div>
      </div>
      <div
        className="my-3"
        dangerouslySetInnerHTML={{ __html: sala?.descripcion }}
      ></div>

      <div className="">
        <Carousel
          responsive={{
            superLargeDesktop: {
              breakpoint: { max: 4000, min: 3000 },
              items: 3,
            },
            desktop: {
              breakpoint: { max: 3000, min: 1024 },
              items: 3,
            },
            tablet: {
              breakpoint: { max: 1024, min: 464 },
              items: 2,
            },
            mobile: {
              breakpoint: { max: 464, min: 0 },
              items: 1,
            },
          }}
          infinite={true}
          autoPlay={true}
        >
          {CarouselSala(sala).map((evento, index) => (
            <div
              className="item p-2"
              style={{
                background: "none",
                width: "100%",
              }}
              key={index}
            >
              <div
                className="overflow-hidden"
                style={{
                  background: "none",
                  width: "100%",
                  height: "400px",
                  borderRadius: "20px",
                }}
              >
                <img
                  src={`${PATH_DESCRIPTION_PRODUCTION}${evento}`}
                  className="w-100 h-100"
                  alt=""
                />
              </div>
            </div>
          ))}
        </Carousel>
      </div>

      <section className="mb-5">
        <div className="container text-center">
          <h2 className={`fs-5 ${eventos?.data?.length > 0 ? "" : "d-none"}`}>
            Próximos espectáculos
          </h2>
          <div className="">
            <Carousel
              responsive={{
                superLargeDesktop: {
                  breakpoint: { max: 4000, min: 3000 },
                  items: 5,
                },
                desktop: {
                  breakpoint: { max: 3000, min: 1024 },
                  items: 4,
                },
                tablet: {
                  breakpoint: { max: 1024, min: 464 },
                  items: 2,
                },
                mobile: {
                  breakpoint: { max: 464, min: 0 },
                  items: 1,
                },
              }}
              infinite={true}
              autoPlay={true}
            >
              {eventos?.data
                ? eventos?.data.map((evento, index) => (
                    <div
                      className="item p-2"
                      style={{
                        background: "none",
                        width: "100%",
                        borderRadius: "20px",
                      }}
                      key={index}
                    >
                      <Evento data={evento} descount={false} />
                    </div>
                  ))
                : [1, 2, 3, 4].map((evento, index) => (
                    <div
                      className="item p-2"
                      style={{
                        background: "none",
                        width: "100%",
                        borderRadius: "20px",
                      }}
                      key={index}
                    >
                      <EventoPlaceholder />
                    </div>
                  ))}
            </Carousel>
          </div>
        </div>
      </section>

      <section
        className="py-3 border border-bottom-0 border-start-0 border-end-0 border-dark"
      >
        <div className="container">
          <div className="text-center my-5 p-1">
            <div className="d-flex justify-content-between">
              <span></span>
              <div className="text-end">
                <span className="d-block fw-bold">{sala?.email}</span>
                <span className="d-block ">{`${sala?.direccion}, ${sala?.localidad} ${sala?.provincia}`}</span>
                <span className="d-block ">{sala?.email}</span>
                <span className="d-block ">{sala?.telefono}</span>
              </div>
            </div>
            <iframe
              src={
                "https://maps.google.com/?q=" +
                encodeURI(
                  `${sala?.direccion}, ${sala?.localidad} ${sala?.provincia}`
                ) +
                "&z=14&t=m&output=embed"
              }
              style={{ border: "0" }}
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
              height="400px"
              className="my-3 w-100"
            ></iframe>
          </div>
        </div>
      </section>
    </div>
  );
};
export default Sala;
