import { useParams } from "react-router-dom";
import Header from "../components/body/Header";
import Footer from "../components/body/Footer";
import { useEffect, useState } from "react";
import Nota from "../page/Nota";
import apiAntesala from "../../config/ConfigPublic";

export const NotaController = () => {
  const [nota, setNota] = useState(null);
  const { slug } = useParams();

  const fetch = async (setData, params, url) => {
    const response = await apiAntesala.get(url, { params });
    if (response.status == 201 || response.status == 200) {
      setData(response.data);
    }
  };

  useEffect(() => {
    if (!nota) {
      fetch(setNota, {}, `/notas/${slug}`);
    }
  }, []);
  return (
    <div className="container-fluid px-0 bg-light">
      <div className="w-100 shadow-sm mb-1 fixed-top bg-white">
        <Header discount={false} />
      </div>

      <div className="container-md min-vh-100 ">
        <Nota data={nota} />
      </div>
      <div className="w-100 container-fluid px-0 mx-0">
        <Footer />
      </div>
    </div>
  );
};
