import { useState } from "react";
import { PATH_VIDEO_PRODUCTION } from "../../../const/PATH";
import { format } from "date-fns";
import { es } from "date-fns/locale";
import { DateRange } from "react-date-range";

const Video = ({ data, completa, onClick }) => {
  const [image, setImage] = useState(`${PATH_VIDEO_PRODUCTION}${data?.imagen}`);

  const manejarError = () => {
    setImage("/assets/img/eventos/imageNotFound.svg");
  };

  if (completa) {
    return (
      <label
        class="row bg-light p-0 m-0 overflow-hidden"
        style={{ borderRadius: "20px", cursor: "pointer" }}
      >
        <div className="col-md-12 overflow-hidden p-0 m-0">
          <img
            src={image}
            width={"100%"}
            height={"100%"}
            alt=""
            onError={manejarError}
          />
        </div>
        <div
          className="text-dark px-3 py-4 border-0 col-md-12"
          style={{
            bottom: "0px",
            borderRadius: "0px 0px 0px 0px",
          }}
        >
          <div className="mb-2">
            <span className="fs-7 mb-2 text-dark d-block GothamLight">
              {format(
                new Date(data?.created_at),
                "eeee, d 'de' MMMM 'de' yyyy",
                {
                  locale: es,
                }
              ).toString()}
            </span>
            <span className="fs-5 GothamBold text-uppercase ">
              {data.titulo}
            </span>
          </div>
          <p className="fs-8 GothamLight lh-1">{data.descripcion}</p>
        </div>
        <button
          className="d-none"
          data-bs-toggle="modal"
          data-bs-target="#exampleModal"
          name="reproducir"
          onClick={(e) => onClick(e, data)}
        ></button>
      </label>
    );
  }

  return (
    <label
      class="overflow-hidden"
      style={{
        width: "100%",
        zIndex: 2,
        border: "1px solid #3d3d3d",
      }}
    >
      <div
        style={{
          position: "relative",
          width: "100%",
          maxWidth: "600px",
          overflow: "hidden",
        }}
      >
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            background:
              "linear-gradient(to bottom, transparent, transparent, rgba(61, 61, 61, 0.7), rgba(61, 61, 61, 0.7), rgba(61, 61, 61, 0.7))",
          }}
        ></div>
        <img
          src={image}
          width="100%"
          height={"auto"}
          alt=""
          onError={manejarError}
        />
      </div>

      <div
        className="text-white px-3 py-4 border-0 box-content"
        style={{
          position: "absolute",
          bottom: "0px",
          left: "0px",
          zIndex: 1,
        }}
      >
        <div className="mb-2">
          <span className="fs-7 mb-2 text-white d-block GothamLight">
            {format(new Date(data?.created_at), "eeee, d 'de' MMMM 'de' yyyy", {
              locale: es,
            }).toString()}
          </span>
          <span className="fs-5 GothamBold text-uppercase ">{data.titulo}</span>
        </div>
        <span className="btn btn-custom-amarillo ">
          <span className="d-flex fs-8 GothamBold justify-content-center items-align-center text-dark ">
            <i class="bi bi-play-circle fs-5"></i>
          </span>
        </span>
      </div>

      <button
        className="d-none"
        data-bs-toggle="modal"
        data-bs-target="#exampleModal"
        name="reproducir"
        onClick={(e) => {
          document.getElementById('iframe-video').src = data.url;
        }}
      ></button>
    </label>
  );
};

export default Video;
